import { createContext, useContext, useEffect, useState } from "react";
import { getDeviceInfo } from "../components/utils/logs/logActivity";

const UserActivityContext = createContext();

export const UserActivityProvider = ({children}) => {
  const [deviceInfo, setDeviceInfo] = useState({});

  useEffect(() => {
    const info = getDeviceInfo();
    setDeviceInfo(info);
  }, []);

  const logActivity =  async (activityData) => {
    const payload = {
      ...activityData,
      device_info: deviceInfo,
    };

    // console.log('Logging activity with payload:', payload);

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/log-activity`, requestOptions);
      
      if (!response.ok) {
          const errorText = await response.text(); // Read error message
          console.error(`Error logging activity: ${response.status} - ${errorText}`);
      }
    } catch (error) {
        console.error('Fetch error:', error);
    }
  };

  return (
    <UserActivityContext.Provider value={{ logActivity }}>
      {children}
    </UserActivityContext.Provider>
  )
};



export const useUserActivity = () => useContext(UserActivityContext);

