import { Avatar, Box, Flex, Link, Paragraph, Text, useColorMode } from "theme-ui";
import { formatDistanceToNow } from "date-fns";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReplyButton from "../../../components/linkup/button/reply-button";
import CommentVote from "../../../components/linkup/pills/comment-votes-pill.component";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";



const CommentsByUser = () => {
    const {username} = useParams();
    const [comments, setComments] = useState([]);
    const [error] = useState(null);
    const [colorMode] = useColorMode();
    const navigate = useNavigate();
  
    const handleItemClick = (item) => {
      navigate(`/linkup/p/${item.subthread_id}/comments/${item.post_id}/comment/${item.id}`, {state: {item}})
    };
    
  
    useEffect(() => {
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
  
      const requestOptions = {
        method: "GET",
        headers: headers,
      }
  
      fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/users/${username}/comments`, requestOptions)
      .then((response) => {
  
        return response.json();
      })
      .then((data) => {
        if (data.comments && Array.isArray(data.comments)){
          setComments(data.comments);
        } else {
          throw new Error('Unexpected data format');
        }
      })
      .catch((err) => {
        console.error(err);
      });
    }, [username]);
  
    if (error) {
      return <div>Error: {error}</div>
    }
    if (!Array.isArray(comments)) {
      return <div>No comments available</div>
    }
  
  return (

        <>
        {comments.map((item) => (
          <Box key={item} className="overview-item"
          sx={{
            cursor: "pointer",
            transition: "background-color 0.3s ease",
            backgroundColor: colorMode === 'dark' ? 'background' : 'white',
            '&:hover': {
              backgroundColor: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#f0f0f0',
            },
            border: 0, borderBottom: '1px solid', borderColor: 'gray'
          }}
          onClick={() => handleItemClick(item)}
    
          >
          <Flex sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', pt: '0.5rem'}}>
            <Box>
              <Link
                href={`/linkup/t/${item.subthread_id}`}
                sx={{
                  fontWeight: 'bold',
                  color: 'inherit', 
                  textDecoration: 'none', 
                  '&:hover': {
                    color: 'primary'
                }}}
              >
                <Avatar 
                  src={item.avatar_url ? item.avatar_url : `https://robohash.org/${item.subthread_id}/set=set3`}
                  sx={{
                    backgroundColor: 'white', 
                    width: 30, 
                    height: 30,
                    borderRadius: 99999,
                    border: '1px solid lightgrey',
                  }} 
                  alt=""
                />
              </Link>
            </Box>
            <Box sx={{fontSize: '14px'}}>
              <Link href={`/linkup/t/${item.subthread_id}`} 
                pl={2}
                sx={{
                  fontWeight: 'bold',
                  color: 'inherit', 
                  textDecoration: 'none', 
                  '&:hover': {
                    color: 'primary'
                }}}
              >t/{item.subthread_id}</Link> • <Text>{item.post_title}</Text>
            </Box>
          </Flex>
          <Box pl='2.4rem'>
            {item.parent_comment ? 
              <Paragraph>
                  <Text sx={{fontWeight: 'bold'}}>{item.username} </Text> 
                  <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`replied to `}</Text> 
                  <Text sx={{fontWeight: 'bold'}}>{item.parent_comment.username} </Text> 
                  <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
                </Paragraph> 
            :
              <Paragraph>
                <Text sx={{fontWeight: 'bold'}}>{item.username} </Text> 
                <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`commented ${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
              </Paragraph> 
            }
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: ({node, ...props}) => {
                  return props.src && props.src.endsWith('.gif') ? (
                    <img {...props} style={{width: '25%', borderRadius: '5px'}} alt=""/>
                  ) : (
                    <img {...props} alt="" />
                  );
                }
              }}
            >{item.content}</ReactMarkdown>
            <Flex sx={{mb:2 , alignItems: 'center'}}>
              <CommentVote comment={item} />
              <ReplyButton comment={item} />
            </Flex>
          </Box>
          </Box>
        )
        
        )}
        </>     

  )
}

export default CommentsByUser;