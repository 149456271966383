import { createContext, useContext, useEffect, useState } from "react";
import { AuthContext } from "./auth";
import { useParams } from "react-router-dom";
import { useAlert } from "./alert";


const CommentContext = createContext();

export const CommentProvider = ({children}) => {
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const {subname, pid} = useParams();
  const {jwtToken} = useContext(AuthContext);
  const {showAlert} = useAlert();

  const fetchComments = async () => {
    // console.log('Fetching comments items:', subname, pid, jwtToken);
    if (!subname || !pid) return;
    if (hasFetched) return;
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/show/comments`, requestOptions);
      // console.log(response);
      if (!response.ok) {
        throw new Error("Failed to fetch comments");
      }
      const data = await response.json();
      // console.log("fetched comments:", data);
      if (!data.comments) {
        return
      }
      const initializedComments = data.comments.map(comment => ({
        ...comment,
        replies: comment.replies || [],
      }))
      setComments(initializedComments);
      setHasFetched(true);
    } catch (err) {
      setError(err.message);
      console.error("Error fetching comments:", err);
    }
  };
  
  const addComment = async (content) => {
    if (!jwtToken) {
      showAlert("You must be logged in to comment.", "alert");
      return
    }
    if (!subname || !pid || !jwtToken) return;
    try {
      var payload = {
        content: content,
      } 
      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/comment/submit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error("Failed to add comment");
      }
      const data = await response.json();
      // console.log("Comment added:", data);
      setComments((prevComments) => [data.comment, ...prevComments]);
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const addReply = async (parentId, content) => {
    if (!jwtToken) {
      showAlert("You must be logged in to reply.", "alert");
      return
    }
    if (!subname || !pid || !jwtToken) return;

    try {
      var newComment = {
        content: content,
        // author_id: authorId,
        // parent_id: parentId,
        // replies: [],
      };

      var requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwtToken}`,
        },
        body: JSON.stringify(newComment),
      }

      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/comment/${parentId}/submit`, requestOptions);

      if (!response.ok) {
        throw new Error("Failed to add reply");
      }

      const data = await response.json();
      // console.log("Reply added:", data);
      
      
      setComments(prevComments => {
        const updateReplies = (comments, parentId, newReply) => {
          return comments.map(comment => {
            // Check if this comment is the parent comment
            if (comment.id === parentId) {
              // Return updated comment with new reply added
              return {
                ...comment,
                replies: [...(comment.replies || []), newReply], // Append new reply
              };
            }
  
            // Check if there are replies and recursively update
            if (comment.replies && comment.replies.length > 0) {
              return {
                ...comment,
                replies: updateReplies(comment.replies, parentId, newReply),
              };
            }
  
            return comment; // Return unchanged comment
          });
        };
  
        return updateReplies(prevComments, parentId, data.comment);
      });
      return data;
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  
  };

  useEffect(() => {
    if (subname && pid) {
      fetchComments();
    }
  }, [subname, pid]);

  return (
    <CommentContext.Provider value={{comments, addComment, fetchComments, addReply, error}}>
      {children}
    </CommentContext.Provider>
  )
}

export const useComments =  () => useContext(CommentContext);