import { Box, Flex, IconButton, NavLink, useColorMode } from 'theme-ui';


import { Link, useLocation } from 'react-router-dom';
import ColorModeToggle from '../../../components/colormode-toggle.component.jsx';
import { Fragment, useContext, useEffect, useState } from 'react';
import AlertMsg from '../../../components/alert/alertMsg.component.jsx';
import { AuthContext } from '../../../contexts/auth.js';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navigation = () => {
  const {alertMsg} = useContext(AuthContext);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [colorMode] = useColorMode();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  
 
  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  useEffect(() => {
    if (location.pathname !== currentPath) {
      setIsDropdownOpen(false);
      setCurrentPath(location.pathname);
    }
  }, [location.pathname, currentPath]);

  return (
    <Fragment>
      <Flex as={"nav"} sx={{ 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: ['1rem', '1.5rem'], 
        textTransform: 'uppercase', 
        letterSpacing: '0.1em',
        position: 'fixed',
        top: 0,
        right: 0,
        width: '100vw',
        zIndex: 999,
        backgroundColor: 'background',
        boxShadow:  colorMode === 'dark' ? 'dark' : 'light',
        overflowX: 'hidden'
        }}>
        <NavLink as={Link} to='/' p={2} ml={2} onClick={() => {
          const homeSection = document.getElementById("root");
          if (homeSection) {
            homeSection.scrollIntoView({ behavior: "smooth" });
          }
        }}>
          Home
        </NavLink>

        {/* Hamburger Icon for Small Screens */}
        <IconButton
          
          onClick={toggleDropdown}
          sx={{ 
            cursor: 'pointer', 
            display: ['block', 'none', 'none'], 
            ml: 'auto' 
          }}
          aria-label="Toggle menu"
        >
          {isDropdownOpen ? <FaTimes /> : <FaBars />}
        </IconButton>

        {/* Full Navigation for Larger Screens */}
        <Flex as="nav" sx={{ display: ['none', 'flex'], ml: 'auto'}}>
          {location.pathname === '/' && 
            (<NavLink as={Link} p={2} onClick={() => {
              const servicesSection = document.getElementById("services");
              if (servicesSection) {
                servicesSection.scrollIntoView({ behavior: "smooth" });
              }
            }}>
              Services
            </NavLink>
          )}
          
          <NavLink as={Link} to='/about' p={2}>
            About
          </NavLink>
          <NavLink as={Link} to='/contact' p={2}>
            Contact
          </NavLink>
          <NavLink as={Link} to='/linkup/user/wtran' p={2}>
            Profile
          </NavLink>
          <NavLink as={Link} to='/linkup' p={2} sx={{ml: 'auto'}}>
            LinkUp
          </NavLink>
          
          <NavLink p={2} sx={{ paddingTop: '0.67em'}}>
            <ColorModeToggle />
          </NavLink>
        </Flex>

      </Flex>

      {/* Dropdown Menu for Small Screens */}
      {isDropdownOpen && (
        <Box as="nav" sx={{
          position: 'fixed',
          top: '60px',
          right: 0,
          width: '200px',
          bg: 'background',
          p: 2,
          display: ['block', 'none'],
          boxShadow: colorMode === 'dark' ? '0px 4px 8px rgba(255, 255, 255, 0.3)': '0px 4px 8px rgba(0, 0, 0, 0.2)',
          borderTop: colorMode === 'dark' ? '1px solid rgba(255, 255, 255, 0.2)' : '1px solid rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            {location.pathname === '/' && (
              <NavLink as={Link} p={2} onClick={() => {
                const servicesSection = document.getElementById("services");
                if (servicesSection) servicesSection.scrollIntoView({ behavior: "smooth" });
                setIsDropdownOpen(false);
              }}>Services</NavLink>
            )}
            <NavLink as={Link} to="/about" p={2} onClick={() => setIsDropdownOpen(false)}>About</NavLink>
            <NavLink as={Link} to="/contact" p={2} onClick={() => setIsDropdownOpen(false)}>Contact</NavLink>
            <NavLink as={Link} to="/linkup/user/wtran" p={2} onClick={() => setIsDropdownOpen(false)}>Profile</NavLink>
            <NavLink as={Link} to="/linkup" p={2} onClick={() => setIsDropdownOpen(false)}>LinkUp</NavLink>
            <NavLink p={2}><ColorModeToggle /></NavLink>
          </Box>

        </Box>
      )}
      <Box as="hr" 
        sx={{
          border:'none',
          borderTop: '1px solid #d3d3d3',
          mx: '1rem',
          mt: '4rem'
        }}
      />
      <AlertMsg message={alertMsg} />
      
    </Fragment>
  )
}

export default Navigation;