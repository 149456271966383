import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex } from 'theme-ui';
import ReactDOM from 'react-dom/client';
import ReactQuill from 'react-quill';
// import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';
import '../../../quillSetup';
import { FaRegFileImage, FaRegSmile } from 'react-icons/fa';
import EmojiPickerComponent from '../../../components/linkup/editor/emoji-picker';
import GifPicker from '../../../components/linkup/editor/gif-picker';
import ErrorBoundary from '../../../components/linkup/errors/error-boundary';

const handleImageUpload = async (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Resize to fit within a max width/height
      let { width, height } = img;
      const maxWidth = 800; // Max dimensions for optimization
      const maxHeight = 800;
      if (width > maxWidth || height > maxHeight) {
        if (width > height) {
          height = (height / width) * maxWidth;
          width = maxWidth;
        } else {
          width = (width / height) * maxHeight;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Image optimization failed"));
          }
        },
        "image/webp", // Convert to WebP format
        0.8 // Compression quality
      );
    };
    reader.readAsDataURL(file);
  });
};

const handleImageInsert = async (editor, file) => {
  try {
    const optimizedBlob = await handleImageUpload(file);
    const optimizedUrl = URL.createObjectURL(optimizedBlob);

    const range = editor.getSelection();
    if (range) {
      editor.insertEmbed(range.index, "image", optimizedUrl);
      editor.setSelection(range.index + 1);
    }
  } catch (error) {
    console.error("Error inserting image:", error);
  }
};

const EmojiButton = ({ onClick }) => {
  // const handleClick = () => {
  //     if (quillRef && quillRef.current) {
  //     const editor = quillRef.current.getEditor();
  //     editor.focus();
  //   }
  //   onClick();
  // };
  
  return (
    <button className="ql-emoji" onClick={onClick} style={{display:'flex', alignItems: 'center'}}>
      <FaRegSmile />
    </button>
  );
};

const GifButton = ({onClick}) => {
  // const handleClick = () => {
  //   if (quillRef && quillRef.current) {
  //     const editor = quillRef.current.getEditor();
  //     editor.focus();
  //   }
  //   onClick();
  // };
  return (
    <button className="ql-gif" onClick={onClick} style={{display: 'flex', alignItems: 'center'}}>
      <FaRegFileImage />
    </button>
  )
}

const QuillPostEditor = ({setContent, content}) => {
  const quillRef = useRef(null);
  const buttonsAdded = useRef(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  
  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      quillRef.current.editor = editor;
      editor.on('text-change', () => {
        // console.log('Quill content:', editor.root.innerHTML);
        setContent(editor.root.innerHTML);
      });
      // if (editor) {
      // }
    }
  }, [setContent]);
  

  const addCustomButtons = () => {
    if (quillRef.current && !buttonsAdded.current) {
      const editor = quillRef.current.editor;
      const toolbar = editor.getModule('toolbar');
      const toolbarContainer = toolbar.container;
  
      // Helper function to check and add buttons
      const addButton = (buttonClass, ButtonComponent, onClickHandler) => {
        if (!toolbarContainer.querySelector(buttonClass)) {
          const buttonContainer = document.createElement('span');
          buttonContainer.className = `ql-formats`;
          const buttonRoot = document.createElement('div');
          buttonContainer.appendChild(buttonRoot);
          toolbarContainer.appendChild(buttonContainer);
  
          // Render the ButtonComponent to the root
          ReactDOM.createRoot(buttonRoot).render(
            <ButtonComponent onClick={onClickHandler} />
          );
        }
      };
  

      addButton('.ql-emoji', EmojiButton, () => setShowPicker(prev => !prev));
      addButton('.ql-gif', GifButton, () => setShowGifPicker(prev => !prev));


      buttonsAdded.current = true;
    }
  
  };  

  // const handleTableInsert = (rows, columns) => {
  //   const quill = quillRef.current.getEditor();
  //   const tableModule = quill.getModule('better-table-plus');
  //   tableModule.insertTable(rows, columns);
  // };

  const insertGif = (editor, gifUrl) => {
    editor.focus();
    const range = editor.getSelection();
    // console.log('Inserting GIF at range:', range);
  
    if (range) {
      editor.insertEmbed(range.index, 'image', gifUrl);
        // console.log('Insert embed result:', editor.getContents());
      setTimeout(() => {
        const images = document.querySelectorAll('.ql-editor img');
        images.forEach(img => {
          if (img.src === gifUrl) {
            img.style.borderRadius = '5px';
            img.style.width = '25%';
            img.style.height = 'auto';
          }
        });
      }, 0);
      // editor.insertEmbed(range.index, 'image', gifUrl);
      // console.log(editor.insertEmbed(range.index, 'image', {
      //   src: gifUrl, 
      //   style: `width: 25%; border-radius: 5px;`
      // }));
      
      editor.setSelection(range.index + 1);
      
    }
  };

  const handleGifSelect = (gifUrl) => {
    // console.log(gifUrl);
    const editor = quillRef.current.editor;
    insertGif(editor, gifUrl);
    setShowGifPicker(false);
    // if (editor) {
    // }
  };

  useEffect(() => {
    if (quillRef.current) {
      addCustomButtons();
      // console.log('Quill instance:', quillRef.current.getEditor());
    }
  }, []);

  const modules = {
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'align': ['right', 'center', 'justify'] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, {'list': 'check'}],
        ['link', 'image'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean'],
      ],
      handlers: {
        // table: () => setIsModalOpen(true),
        // image: function () {
        //   const input = document.createElement("input");
        //   input.setAttribute("type", "file");
        //   input.setAttribute("accept", "image/*");
        //   input.click();
  
        //   input.onchange = async () => {
        //     const file = input.files[0];
        //     if (file) {
        //       const editor = quillRef.current.editor;
        //       await handleImageInsert(editor, file);
        //     }
        //   };
        // },
      },
    },
    // 'better-table-plus': {},
    
  };
  

  return (
    <Box sx={{fontSize: '0.9rem', mt: 2}}>
      <Flex sx={{ justifyContent: 'space-between', alignItems:'center', gap: 2 }}>
        <Box className="quill-container" style={{width:"100%"}}>
          <ReactQuill 
            ref={quillRef}
            value={content}
            onChange={(value) => setContent(value)}
            modules={modules}
            style={{ width: "100%"}}
          />
          {showPicker && (
            <div className='emoji-picker-container'>
              <ErrorBoundary>
                <EmojiPickerComponent quillRef={quillRef} setContent={setContent} />
              </ErrorBoundary>
            </div>
          )}
          {showGifPicker && (
            <div className='gif-picker-container'>
              <GifPicker onSelect={handleGifSelect} />
            </div>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default QuillPostEditor;