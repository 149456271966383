import { createContext, useContext, useState } from "react";


const VoteContext = createContext();

export const VoteProvider = ({children}) => {
  const [votes, setVotes] = useState({});

  const updateVote = (post, voteType) => {
    setVotes(prev => ({
      ...prev,
      [post.id]: voteType
    }));
  }

  return (
    <VoteContext.Provider value={{votes, updateVote}}>
      {children}
    </VoteContext.Provider>
  )
}

export const useVotes = () => useContext(VoteContext);