import { Box, Flex, NavLink, useColorMode } from 'theme-ui';

import { useLocation, useNavigate, Link } from 'react-router-dom';
import ColorModeToggle from '../../../components/colormode-toggle.component.jsx';
import { Fragment, useContext } from 'react';
import AlertMsg from '../../../components/alert/alertMsg.component.jsx';
import { AuthContext } from '../../../contexts/auth.js';
import { FaPlus } from 'react-icons/fa';
import SearchSubthreadBar from '../../../components/search/search-thread-bar.jsx';


const LinkupNav = () => {
  const { jwtToken, setJwtToken, alertMsg, toggleRefresh, setAuthUser, authUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const section = location.pathname.split('/');
  const isSubthread = section[2] === 't';
  const subthreadName = isSubthread ? section[3] : null;
  const createPath = isSubthread ? `/linkup/p/${subthreadName}/submit` : '/linkup/submit'
  const [colorMode] = useColorMode();

  
  const handleLogout = () => {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/logout`, requestOptions)
    .then((response) => {
      if (response.ok) {
        // console.log("Sucessfully logged out");
        alertMsg("Sucessfully logged out", "success")
      }
    })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setJwtToken("");
      setAuthUser("");
      toggleRefresh(false);
      navigate("/linkup/login");
    })
  }





  return (
    <Fragment>
      <Flex as={"nav"} sx={{ 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        p: 2, 
        textTransform: 'uppercase', 
        letterSpacing: '0.1em',
        position: 'fixed', 
        top: 0, 
        width: '100%', 
        zIndex: 999, 
        backgroundColor: 'background', 
        borderBottom: '1px solid #d3d3d3',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' 
      }}
      >
        <NavLink as={Link} to='/linkup' p={2}
          style={{ 
            textDecoration: 'none', 
            padding: '1rem', 
            // marginLeft: 'auto' 
          }}
        >
          LinkUp
        </NavLink>
        <Box  sx={{
          ml: 'auto',
          width: '30%',
          pt: 1,
        }}>

        <SearchSubthreadBar />
        </Box>
        {!jwtToken 
        ? <NavLink as={Link} to={'/linkup/login'} p={2} sx={{ml: 'auto'}} >
          Login
        </NavLink> 
        : 
        <>
          <NavLink as={Link} to={createPath} 
            p={2} 
            sx={{
              ml: 'auto', 
              display: 'flex', 
              justifyContent:'space-between',
              alignItems: 'center',
              backgroundColor: colorMode === 'dark' ? 'background': 'inherit',
              '&:hover': {
                color: colorMode === 'dark' ? 'black' : 'inherit',
                backgroundColor: '#e0e0e0',
                borderRadius: '1rem',
              },
            }}
          >
            
            <FaPlus style={{marginRight:'0.2rem'}}  />Create
          </NavLink>
          <NavLink as={Link} p={2} to={`/linkup/user/${authUser}`} sx={{cursor:'pointer'}} >
            Profile
          </NavLink>
          <NavLink p={2} onClick={handleLogout} sx={{cursor:'pointer'}}>
            Logout
          </NavLink> 
        </>
        }
        <NavLink p={2}>
          <ColorModeToggle />
        </NavLink>
      </Flex>
      <Box as="hr" 
        sx={{
          border:'none',
          
          mx: '1rem',
          mt: '3rem'
        }}
      />
      <AlertMsg message={alertMsg} />

    </Fragment>
  )
}

export default LinkupNav;