import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "./auth";


const SubthreadContext = createContext();

export const SubthreadProvider = ({children}) => {
  const [subthread, setSubthread] = useState(null);
  const [error, setError] = useState(null);
  const {jwtToken} = useContext(AuthContext);
  let {subname} = useParams();

  const fetchSubthread = async () => {
    if (!subname || !jwtToken) return;
    setError(null);
    try {
      // console.log(`fetch subthread ${subname}`)
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization",  `Bearer ${jwtToken}`);
      const requestOptions = {
        method: 'GET',
        headers: headers,
      }

      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthread/${subname}`, requestOptions);
      
      if (!response.ok) {
        throw new Error("Failed to fetch subthread")
      }
      const data = await response.json();
      setSubthread(data.subthread);
    } catch(err) {
      setError(err.message)
    }

  }

  useEffect(() => {
    fetchSubthread();

  }, [subname, jwtToken]);


  return (
    <SubthreadContext.Provider value={{subthread, fetchSubthread, error}}>
      {children}
    </SubthreadContext.Provider>
  );
};


export const useSubthread = () => useContext(SubthreadContext);
