import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, Heading, Input, Paragraph } from "theme-ui";
import { useAlert } from "../../../contexts/alert";


const ResendActivation = () => {
 const [email, setEmail] = useState('');
 const [message, setMessage] = useState('');
 const location = useLocation();
 const {showAlert} = useAlert();
 const navigate = useNavigate();
 
 useEffect(() => {
  const queryParams = new URLSearchParams(location.search);
  const emailFromURL = queryParams.get('email');


  if (emailFromURL) {
    setEmail(emailFromURL);
  } else {
    setMessage('Email parameter missing.');
  }
}, [location]);

 const handleSubmit = (e) => {
  e.preventDefault();
  let payload = {
    email: email,
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/user/activation`, requestOptions)
  .then((resp) => {
    return resp.json();
   })
   .then((data) => {
    if (data.error) {
      showAlert(data.error.message.email || 'Failed to resend activation link email.', 'alert');
      navigate("/linkup/login");
    }
    showAlert(data.message, "success");
    navigate("/linkup");
   })
   .catch((error) => {
    console.error("Error:", error)
   })
  }

 return (
  <>
    <Container mt={6} sx={{width: '20em'}}>
      <Heading mb={3}>Activation Link Expired!</Heading>
      {message && <Paragraph>{message}</Paragraph>}
      <Box as='form' onSubmit={handleSubmit} mb={3}>
        <Paragraph mb={3}>Please request a new link to this email.</Paragraph>
        <Input 
          type="email"
          value={email}
          sx={{ cursor: 'not-allowed' }}
          readOnly
          mb={3}
        />
        <Button sx={{cursor:'pointer'}} type="submit">Request Activation Link</Button>
      </Box>
    </Container>
  </>
 )
}

export default ResendActivation;