import { Avatar, Box, Flex, Link as NavLink } from "theme-ui";
import { Link } from "react-router-dom";



const RecentPostsHeader = ({post}) => {

  return (
    <Flex sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', pt: '0.5rem'}}>
      <Box>
        <NavLink as={Link}
          to={`/linkup/t/${post.subthread_id}`}
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >
          <Avatar 
            src={post.avatar_url ? post.avatar_url : `https://robohash.org/${post.subthread_id}/set=set3`}
            sx={{
              backgroundColor: 'white', 
              width: 30, 
              height: 30,
              borderRadius: 99999,
              border: '1px solid lightgrey',
            }} 
          />
        </NavLink>
      </Box>
      <Box sx={{fontSize: '14px'}}>
        <NavLink as={Link} to={`/linkup/t/${post.subthread_id}`} 
          pl={2}
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >t/{post.subthread_id}</NavLink>
      </Box>
    </Flex>
  )
}

export default RecentPostsHeader; 