import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Button, Container, Flex, Heading, Paragraph, Progress, Text } from "theme-ui";
import isValidEmail from "../../utils/regex/regex.utils";
import FormInput from "../../form/form-input";
import { useAlert } from "../../../contexts/alert";
import { validatePassword } from "../../utils/auth/auth.utils";


const Signup = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [email, setEmail] = useState("");
  const [emailExists, setEmailExists] = useState(false);
  const [check, setCheck] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const {showAlert} = useAlert();
  const [usernameExists, setUsernameExists] = useState(false);
  const navigate = useNavigate();

  const handleNextStep = () => {
    if (emailExists || email.length < 1 || !isValidEmail(email)) {
      showAlert('Must enter a valid email.')
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleUsernameChange = (e) => {
    const value = e.target.value;
    const noSpaceFormat = value.replace(/[^a-zA-Z0-9_]/g, "");
    if (noSpaceFormat.length <= 20) {
      setUsername(noSpaceFormat);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    const error = validatePassword(value);
    setPasswordError(error);
    setPasswordMatchError(confirmPassword && confirmPassword !== value ? "Passwords do not match." : "");
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordMatchError(value !== password ? "Passwords do not match.": "");
  }

  useEffect(() => {
    if (username.length === 0) {
      setUsernameExists(false);
      return;
    }
  
    const debounce = setTimeout(() => {
      let payload = { username };
  
      fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/username_exists`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      })
        .then((resp) => resp.json())
        .then((data) => {
          // console.log(data);
          setUsernameExists(data);
        })
        .catch((error) => console.error("Error:", error));
    }, 500); 
  
    return () => clearTimeout(debounce);
  }, [username]);

  useEffect(() => {
    const checkEmailExists = () => {
      if (email.length > 0 && isValidEmail(email)) {
        setCheck(true);
        let payload = {
          email: email,
        }
        const requestOptions = {
          method: "POST",
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }

        fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/email_exists`, requestOptions)
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          // console.log(data);
          setEmailExists(data);
          setCheck(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setCheck(false);
        })
        }
      }
      const debounce = setTimeout(checkEmailExists, 1000);
      return () => clearTimeout(debounce);
    }, [email]);
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (emailExists || email.length < 1) {
        return;
      }

      let payload = {
        username: username,
        email: email,
        password: password,
      }

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }

      fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/users/signup`, requestOptions)
      .then((resp) => {return resp.json()})
      .then((data) => {
        // console.log(data.user)
        showAlert("You have successfully signed up. Check email to activate your account.", "success");
        navigate('/linkup/login');
      })
      .catch((error) => {
        console.error('Error:', error);
        showAlert("Something went wrong. Please try again later.", "alert");
      })
    }

    

  return (
    <Container mt={6} sx={{width: '20em'}}>
      <Heading mb={3}>Sign Up</Heading>
      <Box as="form" onSubmit={handleSubmit} mb={3}>
        {currentStep === 1 && (
          <>
            <FormInput 
              title="Email"
              type="email"
              name="email"
              autoComplete="email-new"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailExists && <Text sx={{mt: -2, fontSize: '0.75rem', color: 'red', display: 'block'}}>Account already exists!</Text>}
            <Box sx={{pt:'3'}}>
              <Flex sx={{alignItems: 'center', justifyContent:'space-between'}}>
                <Box sx={{width: '25%'}}>
                  <Progress max={2} value={1} />
                </Box>
                  <Button
                    sx={{cursor:'pointer'}}
                    type="button"
                    onClick={handleNextStep}
                  >Next</Button>
              
              </Flex>
            </Box>
          </>

        )}
        {currentStep === 2 && (
          <>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <FormInput 
                  title="Username"
                  type="username"
                  name="username"
                  autoComplete="username-new"
                  value={username}
                  onChange={handleUsernameChange}
                  sx={{width:'100%'}}
                />
                {usernameExists && username.length !== 0 && <Text sx={{mt: -3, fontSize: '0.75rem', color: 'red', width: '100%'}}>Username already exists.</Text>}
                {username.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: 'red', width: '100%'}}>Username must not be blank.</Text>}
                <Text 
                  sx={{ 
                    mt: -3,
                    ml: 'auto',
                    fontSize: '0.75rem', 
                    color: 'grey', 
                    }}
                >{username.length}/20
                </Text>
                <FormInput 
                  title="Password"
                  type="password"
                  name="password"
                  autoComplete="password-new"
                  value={password}
                  onChange={handlePasswordChange}
                  sx={{width:'100%'}}
                />
                {passwordError && (
                  <Text sx={{mt: -3, fontSize: "0.75rem", color: "red", width: "100%"}}>
                    {passwordError}
                  </Text>
                )}
                <FormInput 
                  title="Confirm Password"
                  type="password"
                  name="confirmPassword"
                  autoComplete="password-new"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  sx={{width:'100%'}}
                />
                {passwordMatchError && (
                  <Text sx={{mt: -3, fontSize: "0.75rem", color: "red", width: "100%"}}>
                    {passwordMatchError}
                  </Text>
                )}
              </Flex>
              <Box sx={{pt:'3'}}>
                <Flex sx={{alignItems: 'center', justifyContent:'space-between'}}>
                  <Box sx={{width: '25%'}}>
                    <Progress max={2} value={2} />
                  </Box>
        
                  <Button
                    type="button"
                    onClick={handlePreviousStep}
                    sx={{
                      ml:'auto', 
                      mr:'2', 
                      backgroundColor:'lightgray', 
                      color:'black',
                      cursor:'pointer'
                    }}
                  >Back</Button>
                  <Button
                    sx={{cursor:'pointer'}}
                    type="submit"
                  >Submit</Button>
              
              </Flex>
            </Box>
          </>
        )}
        
        
      </Box>
      <Box mt={3}>
        <Paragraph>
          Already have an account? <RouterLink to="/linkup/login" style={{ textDecoration: "none", color: "primary" }}>
            Log In
          </RouterLink>
        </Paragraph>
        
      </Box>
    </Container>
  )
}

export default Signup;