import { useEffect, useState } from "react"
import { Grid } from "theme-ui";
import ListPost from "../../../components/linkup/card/list-post";


const Posts = () => {
  
  const [posts, setPosts] = useState([]);
  const [error] = useState(null);

  useEffect(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts`, requestOptions)
    .then((response) => {

      return response.json();
    })
    .then((data) => {
      // console.log("post data:", data);
      if (data.posts && Array.isArray(data.posts)){
        setPosts(data.posts);
      } else {
        throw new Error('Unexpected data format');
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  if (error) {
    return <div>Error: {error}</div>
  }
  if (!Array.isArray(posts)) {
    return <div>No posts available</div>
  }
  
  return (
    <>
      <Grid gap={1} columns={[1,1,1]}>
      
        {posts.map((post) => (
          <ListPost key={post.id} post={post}/>
        ))}
      </Grid>
    </>
  )
}

export default Posts;