import { formatDistanceToNow } from "date-fns";
import { Box, Text, Link as NavLink } from "theme-ui";
import intlFormattedDate from "../utils/date/date.utils";
import { Link } from "react-router-dom";


const HeaderInfoWithUsername = ({post}) => {
  return (
    <Box sx={{fontSize: '14px', pt: '1'}}>
        <NavLink as={Link} to={`/linkup/t/${post.subthread_id}`} 
          sx={{
            fontWeight: 'bold',
            color: 'inherit', 
            textDecoration: 'none', 
            '&:hover': {
              color: 'primary'
          }}}
        >t/{post.subthread_id}</NavLink> • <Text 
          title={`${intlFormattedDate(post.created_at)}`}
          sx={{fontStyle:'oblique'}}>{`${formatDistanceToNow(new Date(post.created_at))} ago`}
        </Text>
        <Box>
          <NavLink as={Link} to={`/linkup/user/${post.username}`} 
            sx={{
              color: 'inherit', 
              textDecoration: 'none', 
              '&:hover': {
                color: 'primary'
              }}}
          >{post.username}</NavLink>
        </Box>
      </Box>
  )
}

export default HeaderInfoWithUsername;