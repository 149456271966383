import { formatDistanceToNow } from "date-fns";
import { Box, Link as NavLink, Text } from "theme-ui";
import intlFormattedDate from "../utils/date/date.utils";
import React, { useContext, useMemo } from "react";
import { AuthContext } from "../../contexts/auth";
import { Link } from "react-router-dom";


const CommentHeaderInfo = React.memo(({comment, post}) => {
  const { username, created_at, id, post_id } = comment;
  const { sub_name } = post;
  const {authUser} = useContext(AuthContext);

  const formattedUsername = useMemo(() => username || authUser, [username, authUser]);
  const formattedDate = useMemo(() => `${formatDistanceToNow(new Date(created_at))} ago`, [created_at]);

  // useEffect(() => {
    
  //   const createdAtDate = new Date().toISOString();

  //   if (isNaN(createdAtDate)) {
  //     setFormattedDate(`${formatDistanceToNow(createdAtDate)} ago`);
  //   } else {
  //     setFormattedDate(`${formatDistanceToNow(new Date())} ago`);
  //   }
    

  //   if (username) {
  //     setFormattedUsername(username);
  //   } else {
  //     setFormattedUsername(authUser);
  //   }
  // }, [created_at, username, authUser]);

  // console.log(formattedUsername);
  // console.log("date:", formattedDate);
  
  
  // if (!formattedDate || !formattedUsername ) {
  //   return (
  //     <Box sx={{ mr:'auto', alignItems:'center'}}>
  //     <Link href={`/v1/user/${authUser}`} 
  //       sx={{
  //         fontWeight: 'bold',
  //         color: 'inherit', 
  //         textDecoration: 'none', 
  //         '&:hover': {
  //           color: 'primary'
  //       }}}
  //     >{authUser}</Link> • <Link 
      
  //       href={`/v1/posts/${comment.sub_name}/comments/${comment.post_id}/comment/${id}`}
  //       sx={{textDecoration: 'none', color:'inherit'}}
  //       title={`${intlFormattedDate(new Date().toISOString())}`}
  //     >
  //       <Text sx={{fontStyle:'oblique'}}>{`${formatDistanceToNow(new Date().toISOString())} ago`}</Text>
  //     </Link>
  //     </Box>
  //   )
  // }

  return (
    <Box sx={{ mr:'auto', alignItems:'center'}}>
      <NavLink as={Link} to={`/linkup/user/${formattedUsername}`} 
        sx={{
          fontWeight: 'bold',
          color: 'inherit', 
          textDecoration: 'none', 
          '&:hover': {
            color: 'primary'
        }}}
      >{formattedUsername}</NavLink> • <NavLink as={Link}
      
        to={`/v1/posts/${sub_name}/comments/${post_id}/comment/${id}`}
        sx={{textDecoration: 'none', color:'inherit'}}
        title={`${intlFormattedDate(created_at)}`}
      >
        <Text sx={{fontStyle:'oblique'}}>{formattedDate}</Text>
      </NavLink>
      
    </Box>
  )
});

export default CommentHeaderInfo;