import React, { useEffect, useState } from "react";
import { BsCollection, BsCompass, BsFillCollectionFill, BsFillCompassFill, BsFillHouseFill, BsHouse } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import { Container, Flex, Text } from "theme-ui"
import SubthreadNavDropdown from "../../../components/linkup/dropdown/subthread-nav-dropdown.component";

const LeftNav = () => {
  const [active, setActive] = useState(null);
  const location = useLocation();

  const handleLinkClick = (link) => {
    setActive(link);
  }

  useEffect(() => {
    const currentPath = location.pathname;
    const activeLink = links.find(link => link.to === currentPath);
    setActive(activeLink ? activeLink.name : null);
  }, [location]);

  const links = [
    { name: 'Home', icon: <BsHouse />, alt: <BsFillHouseFill/>, to: '/linkup' },
    { name: 'Explore', icon: <BsCompass />, alt: <BsFillCompassFill />, to: '/linkup/explore' },
    { name: 'All', icon: <BsCollection />, alt: <BsFillCollectionFill />, to: '/linkup/all' },
  ];

  return (
    <Container sx={{maxWidth:'20rem', ml: 'auto'}} ml={'auto'}>
      <Flex as="nav" sx={{
        flexDirection: 'column', 
        borderBottom: '1px solid #d3d3d3',
        py: '1rem',
      }}
      >
        {links.map((link) => (
        <Link
            to={link.to} 
            key={link.name}
            style={{ textDecoration: 'none' }} 
            onClick={() => handleLinkClick(link.name)}
          >
            <Flex
              sx={{
                p: 2,
                maxWidth: '100%',
                borderRadius: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem 1rem',
                backgroundColor: active === link.name ? '#f0f0f0' : 'transparent',
                color: active === link.name ? 'black' : 'text',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: active === link.name ? '#f0f0f0' : '#e0e0e0',
                  color: 'black',
                }
              }}
            >
              {active === link.name ? link.alt : link.icon}
              <Text ml={2}>{link.name}</Text>
            </Flex>
          </Link>
        ))}
      </Flex>
      <SubthreadNavDropdown/>
    </Container>
  )
}

export default LeftNav;