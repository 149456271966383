import { useContext, useEffect, useRef, useState } from "react"
import { BsThreeDots } from "react-icons/bs";
import { Box, Link as NavLink } from "theme-ui";
import { AuthContext } from "../../../contexts/auth";
import { Link } from "react-router-dom";


const Dropdown = ({username, url}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)
    && dropdownButtonRef.current && !dropdownButtonRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const {authUser} = useContext(AuthContext);

  const dropDownItems = [
    { href: '#', label: 'Save'},
    ...(authUser === username ? [{ href: `/linkup/${url}/edit`, label: 'Edit'}] : ''),
    ...(authUser === username ? [{ href: '#', label: 'Delete'}] : ''),
    { href: '#', label: 'Hide'},
    { href: '#', label: 'Report'},
  ];


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };



  return (
    <Box ref={dropdownButtonRef} 
    sx={{ 
      position: 'relative', 
      display: 'inline-block', 
      p:'1', 
      '&:hover': { 
        borderRadius: '20px', 
        backgroundColor: '#f0f0f0'
        }
      }}
    >
      <Link onClick={toggleDropdown} sx={{ 
        background: 'none', 
        border: 'none', 
        cursor: 'pointer', 
        padding: 0 }}
      >
        <BsThreeDots />
      </Link>
      {isOpen && (
        <Box ref={dropdownRef} 
        sx={{
          position: 'absolute',
          right: 0,
          
          bg: 'background',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
          zIndex: 10,
          minWidth: '150px',
        }}
        >
          {dropDownItems.map((item, index) => (
            <NavLink as={Link}
              key={index}
              to={item.href}
              sx={{
                display: 'block',
                px: 3,
                py: 2,
                textDecoration: 'none',
                color: 'text',
                '&:hover': {
                  bg: 'muted',
                },
              }}
            >
              {item.label}
            </NavLink>
          ))}
        </Box>
      )}
      
    </Box>
  )
}

export default Dropdown;