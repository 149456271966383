import React, { useEffect, useState } from "react";
import { Container, Grid, Box, Avatar, Heading, Text, Paragraph } from "theme-ui";


const About = () => {

  const profileImages = [
    "https://i.ibb.co/0yCbYdy/profile2.jpg", 
    "https://i.ibb.co/983qWBx/profile1.jpg",
  ];

  const [currentImage, setCurrentImage] = useState(0);
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsSpinning(true);
      setTimeout(() => {
        setCurrentImage((prev) => (prev +1) % profileImages.length);
        setIsSpinning(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [profileImages.length]);

  return (
    <Container py={[1,2,4]}  className="app-container">
      <Grid gap={2} columns={[1, 1, 2, '1fr 2fr']}>
        <Box py={4}>
          <Heading variant='caps' sx={{fontFamily:'Roboto', fontSize: '3rem'}}>
            About
          </Heading>
        </Box>
        <Box py={4}></Box>
        <Box sx={{
          textAlign:'center',
          display: "inline-block",
          width: 200,
          height: 200,
          perspective: 1000, // Adds depth for 3D effect
        }}>
          <Avatar src={profileImages[currentImage]} 
          sx={{
            backgroundColor: 'white', 
            width: [200], 
            height: [200],
            borderRadius: 99999,
            transformStyle: "preserve-3d",
            transform: isSpinning ? "rotateY(90deg)" : "rotateY(0deg)",
            transition: "transform 0.5s ease-in-out",
          }}/>
          <Box p={2} sx={{textAlign:'center'}}>
            <Text sx={{fontWeight:'bold', fontSize:'20px', fontFamily:'Roboto'}}>William Tran</Text>
          </Box>

        </Box>
        <Box  sx={{fontSize:'18px'}}>
          <Paragraph>
            William has 7 years of software engineering experience and over 10+ combined years of finance, accounting and banking experience.
            He focuses on building efficient applications, setting up databases, working with APIs, creating different tools and system integrations. He has worked with 
            programming languages, frameworks and libraries such as Go, Python, C#, Django, Java, JavaScript, and React. 
          </Paragraph>
          <br />
          <Paragraph>
            As a software engineer, he values collaborating with different team members and product owners and has able to 
            successfully deliver projects efficiently, working with cross functioning teams and vendors in a positive and productive 
            environment. He continuously finds ways to grow and look for opportunities to mentor junior engineers. His biggest assets are his ability to solve problems, 
            willingness to learn and not settle, gratitude towards current successes, and humility to accept he does not know everything but have the persistence to figure it out.
          </Paragraph>
          <br/>
          <Paragraph>
            As a technologist, he has taken interest in learning about distributed systems, working with concurrency, and exploring machine learning with Go. 
          </Paragraph>
          <br/>
          <Paragraph>
            On his free time, he is typically seen spending time with his Corgi, Bentley at random places and dog parks. You may also find him lifting weights at the gym so he can 
            stuff himself with tacos, sushi, hotpot, etc. 
          </Paragraph>
        </Box>
      </Grid>
      
    </Container>
  )
}


export default About;