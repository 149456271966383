import { Link } from "react-router-dom";
import { Avatar, Box, Link as NavLink} from "theme-ui";


const CommentAvatar = ({comment}) => {
  
  return (
    <Box>
      <NavLink
        as={Link}
        className="author"
        to={`/linkup/user/${comment.username}`}
        sx={{
          fontWeight: 'bold',
          color: 'inherit', 
          textDecoration: 'none', 
          '&:hover': {
            color: 'primary'
        }}}
      >
        <Avatar 
          
          src={`https://robohash.org/${comment.username}/set=set1`}
          sx={{

            backgroundColor: 'white', 
            width: 35, 
            height: 35,
            borderRadius: 99999,
            border: '1px solid lightgrey',
            margin: 'auto'
          }} 
        />
      </NavLink>
    </Box>
  )
}

export default CommentAvatar;