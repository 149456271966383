import { Avatar, Box, Container, Flex, Heading, Link as NavLink, Paragraph, Text } from "theme-ui";
import BackButton from "../../../components/linkup/button/back-button";
import HeaderTag from "../../../components/headers/header-tag.component";
import Dropdown from "../../../components/linkup/dropdown/dropdown.component";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import SubthreadAvatar from "../../../components/headers/subthread-avatar";
import HeaderInfoWithUsername from "../../../components/headers/header-info-username.component";
import { Link, useLocation } from "react-router-dom";
import OverviewPostVote from "../../../components/linkup/pills/OverviewPostVotes";
import CommentPill from "../../../components/linkup/pills/comments-pill.component";
import ReplyButton from "../../../components/linkup/button/reply-button";
import CommentVote from "../../../components/linkup/pills/comment-votes-pill.component";
import { formatDistanceToNow } from "date-fns";

const OverviewPostComment = () => {
  const location = useLocation();
  const {item} = location.state || {};

  return (
    <>
      <Container px={[4]} >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: 3 }}>
          <BackButton />
          <HeaderTag avatar={<SubthreadAvatar post={item} />} headerInfo={<HeaderInfoWithUsername post={item} />} sx={{mr: 'auto'}}/>
          <Dropdown username={item.username} url={item.url} />
        </Flex>
          <Box >
            <Heading as="h3" sx={{ mb: 2}}>
              {item.post_title}
            </Heading>
            <Paragraph sx={{ fontSize: '0.9rem'}}>
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
            {item.post_content}
            </ReactMarkdown>
            </Paragraph>
            <Flex sx={{ pt: 3, alignItems: 'center'}}>
              <OverviewPostVote size={'1.5rem'} padding={'1'} post={item}/>
              <CommentPill commentCount={item.post_comment_count} size={'1.5rem'}/>
            </Flex>
            <Box
              sx={{
                py: 1, my: 3, fontSize: '14px',
                border: 0, borderBottom: '1px dashed', borderColor: 'gray'
              }}
            >
              <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>Single comment thread</Box>
                <NavLink as={Link}
                  to={`/linkup/p/${item.subthread_id}/comment/${item.post_id}/${item.post_slug}`}
                  sx={{
                    fontSize: '14px',
                    color: 'primary',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    }
                  }}
                >
                  See all comments
                </NavLink>
              </Flex>
            </Box>
            <Flex sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', py: '0.5rem'}}>
              <Box>
                <NavLink as={Link}
                  to={`/linkup/user/${item.username}`}
                  sx={{
                    fontWeight: 'bold',
                    color: 'inherit', 
                    textDecoration: 'none', 
                    '&:hover': {
                      color: 'primary'
                  }}}
                >
                  <Avatar 
                    src={item.avatar_url ? item.avatar_url : `https://robohash.org/${item.username}/set=set1`}
                    sx={{
                      backgroundColor: 'white', 
                      width: 30, 
                      height: 30,
                      borderRadius: 99999,
                      border: '1px solid lightgrey',
                    }} 
                  />
                </NavLink>
              </Box>
              <Box sx={{fontSize: '14px'}}>
                <NavLink as={Link} to={`/linkup/user/${item.username}`} 
                  pl={2}
                  sx={{
                    fontWeight: 'bold',
                    color: 'inherit', 
                    textDecoration: 'none', 
                    '&:hover': {
                      color: 'primary'
                  }}}
                >{item.username}</NavLink> • <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
              </Box>
            </Flex>
            <Box px={4}>
            <Paragraph sx={{ fontSize: '0.9rem'}}>
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
            >
            {item.content}
            </ReactMarkdown>
            </Paragraph>
            <Flex sx={{pt: 3, alignItems: 'center'}}>
              <CommentVote comment={item} />
              <ReplyButton comment={item} />
            </Flex>
            </Box>
          </Box>
      </Container>
    </>
  )
}

export default OverviewPostComment;