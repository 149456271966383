import { Avatar, Box, Flex, Link as NavLink, Paragraph, Text } from "theme-ui";
import { formatDistanceToNow } from "date-fns";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReplyButton from "../../../components/linkup/button/reply-button";
import CommentVote from "../../../components/linkup/pills/comment-votes-pill.component";
import { Link } from "react-router-dom";



const OverviewComments = ({item}) => {
  return (

        <>
          <Flex sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', pt: '0.5rem'}}>
            <Box>
              <NavLink as={Link}
                to={`/linkup/t/${item.subthread_id}`}
                sx={{
                  fontWeight: 'bold',
                  color: 'inherit', 
                  textDecoration: 'none', 
                  '&:hover': {
                    color: 'primary'
                }}}
              >
                <Avatar 
                  src={item.avatar_url ? item.avatar_url : `https://robohash.org/${item.subthread_id}/set=set3`}
                  sx={{
                    backgroundColor: 'white', 
                    width: 30, 
                    height: 30,
                    borderRadius: 99999,
                    border: '1px solid lightgrey',
                  }} 
                  alt={`${item.subthread_id} image`}
                />
              </NavLink>
            </Box>
            <Box sx={{fontSize: '14px'}}>
              <NavLink as={Link} to={`/linkup/t/${item.subthread_id}`} 
                pl={2}
                sx={{
                  fontWeight: 'bold',
                  color: 'inherit', 
                  textDecoration: 'none', 
                  '&:hover': {
                    color: 'primary'
                }}}
              >t/{item.subthread_id}</NavLink> • <Text>{item.post_title}</Text>
            </Box>
          </Flex>
          <Box pl='2.4rem'>
            {item.parent_comment ? 
              <Paragraph>
                  <Text sx={{fontWeight: 'bold'}}>{item.username} </Text> 
                  <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`replied to `}</Text> 
                  <Text sx={{fontWeight: 'bold'}}>{item.parent_comment.username} </Text> 
                  <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
                </Paragraph> 
            :
              <Paragraph>
                <Text sx={{fontWeight: 'bold'}}>{item.username} </Text> 
                <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`commented ${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
              </Paragraph> 
            }
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: ({node, ...props}) => {
                  return props.src && props.src.endsWith('.gif') ? (
                    <img {...props} style={{width: '25%', borderRadius: '5px'}} alt=""/>
                  ) : (
                    <img {...props} alt=""/>
                  );
                }
              }}
            >{item.content}</ReactMarkdown>
            <Flex sx={{mb:2 , alignItems: 'center'}}>
              <CommentVote comment={item} />
              <ReplyButton comment={item} />
            </Flex>
          </Box>
        </>     

  )
}

export default OverviewComments;