import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Container, Flex, Grid, Heading, Paragraph, Text } from "theme-ui";
import Dropdown from "../../../components/linkup/dropdown/dropdown.component";
import BackButton from "../../../components/linkup/button/back-button";
import InteractBar from "../../../components/linkup/pills/interact.component";
import { AuthContext } from "../../../contexts/auth";
import CommentsList from "../../../components/linkup/comments/comments-list.component";
import AddComment from "../../../components/linkup/button/add-comment-button";
import QuillPostEditor from "./QuillPostEditor";
import HeaderTag from "../../../components/headers/header-tag.component";
import SubthreadAvatar from "../../../components/headers/subthread-avatar";
import HeaderInfoWithUsername from "../../../components/headers/header-info-username.component";
import { useAlert } from "../../../contexts/alert";

const EditPost = () => {
  const [post, setPost] = useState({});
  const [content, setContent] = useState('');
  const [temporaryComment, setTemporaryComment] = useState(null);
  const {showAlert} = useAlert();

  const {subname, pid, slug} = useParams();
  const {jwtToken} = useContext(AuthContext);
  const navigate = useNavigate();

  // console.log("edit post jwtToken: ", jwtToken);
  // console.log(subname, pid, slug);

  const handleUpdatedPost = () => {
    if (!jwtToken) return;

    const payload = {
      'content': content,
    }

    const requestOptions = {
      'method': 'PUT',
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`,
      },
      'body': JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/${slug}`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // console.log("update post data:", data);
      if (data.post) {
        showAlert("Successfully updated post.", "success");
        navigate(`/linkup/${data.post.url}`);
      } else if (data.error) {
        showAlert(data.error.message, "alert");
      }
    })
    .catch((err) => {
      console.error(err);
    }, [jwtToken, subname, pid, slug])
  }

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    const requestOptions = {
      'method': 'GET',
      'headers': {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      },
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/${slug}`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // console.log('get post data:', data);
      setPost(data.post);
      setContent(data.post.content);

    })
    .catch((err) => {
      console.error(err);
    })
  },[jwtToken, subname, pid, slug])

  return (
    <>
      <Container px={[4]} >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: 3 }}>
          <BackButton />
          <HeaderTag avatar={<SubthreadAvatar post={post} />} headerInfo={post && post.subthread_id ? (<HeaderInfoWithUsername post={post} />) : (<Text>Loading...</Text>)} sx={{mr: 'auto'}}/>
          {/* <Dropdown username={post.username} url={post.url} /> */}
        </Flex>
        <Box >
          <Heading as="h3" sx={{ mb: 2}}>
            {post.title}
          </Heading>
          <Paragraph sx={{ fontSize: '0.9rem'}}>
            <Box py={2}> 
              <QuillPostEditor setContent={setContent} content={content} />
            </Box>
          </Paragraph>
          <Grid>
            <Box >
              <Flex sx={{ justifyContent: 'space-between', gap: 2 }}>
                <Button ml={'auto'} onClick={() => navigate(-1)}
                  sx={{backgroundColor: 'lightgrey', color: 'inherit'}}
                >Cancel</Button>
                <Button variant="primary" onClick={handleUpdatedPost}>Save</Button>
              </Flex>
            </Box>
          </Grid>
          <InteractBar post={post} />
        </Box>
        
      </Container>
    </>
  )
}

export default EditPost;