import React from 'react';
import { FaRegLightbulb, FaRegMoon } from 'react-icons/fa';
import { Link, useColorMode } from 'theme-ui';

const ColorModeToggle = () => {
  const [colorMode, setColorMode] = useColorMode();


  return (
      <Link 
      sx={{  display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      onClick={() => {
        setColorMode(colorMode === 'default' ? 'dark' : 'default');
      }}>
       {colorMode === 'default' ? (
        <>
          <FaRegMoon />
        </>
        ) : (
        <>
          <FaRegLightbulb />
        </>
        )}
      </Link>
  )
}

export default ColorModeToggle;