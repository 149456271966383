import { FaDiscord, FaGithub, FaLinkedin } from "react-icons/fa";
import { Box, Container, Flex, Heading, Link, useColorMode } from "theme-ui"
import { SiGmail } from 'react-icons/si';

const SocialMediaLinks = () => {
  const [colorMode] = useColorMode();
  const isDarkMode = colorMode === 'dark';

  return (
    <Container py='4'>
      <Heading as={'h1'}>Connect with me.</Heading>
      <Flex as="nav"
        sx={{
          justiftyContent: 'center',
          alignItems: 'center',
          gap: 3,
        }}
      >
        <Box>
          <Link
            href="https://www.linkedin.com/in/william-tran/"
            target="_blank"
            sx={{
              color: 'primary',
              fontSize: 4,
              '&:hover': {color: 'secondary'},

            }}
            aria-label="LinkedIn"
            >
              <FaLinkedin />
            </Link>
        </Box>
        <Box>
          <Link
            href="https://github.com/wtran29"
            target="_blank"
            sx={{
              color: isDarkMode ? 'white' : 'black',
              fontSize: 4,
              '&:hover': {color: isDarkMode ? 'white' : 'black'},
              
            }}
            aria-label="GitHub"
          >
            <FaGithub/>
          </Link>
        </Box>
        <Box>
          <Link
            href="https://discord.com/users/bi11yg04t"
            sx={{
              color: 'rebeccapurple',
              fontSize: 4,
              '&:hover': {color: 'secondary'},
            }}
            aria-label="Discord"
          >
            <FaDiscord />
          </Link>
        </Box>
        <Box>
          <Link
            href="mailto:wtran4hire@gmail.com"
            sx={{
              color: 'red',
              fontSize: 4,
              '&:hover': {color: 'secondary'},
            }}
            aria-label="Email"
          >
            <SiGmail />
          </Link>
        </Box>
      </Flex>
    </Container>
  )
}

export default SocialMediaLinks;