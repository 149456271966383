import { Link } from "react-router-dom";
import { Avatar, Box, Link as NavLink } from "theme-ui";


const SubthreadAvatar = ({post}) => {
  
  return (
    <Box>
      <NavLink as={Link}
        to={`/linkup/t/${post.subthread_id}`}
        sx={{
          fontWeight: 'bold',
          color: 'inherit', 
          textDecoration: 'none', 
          '&:hover': {
            color: 'primary'
        }}}
      >
        <Avatar 
          src={post.avatar_url ? post.avatar_url : `https://robohash.org/${post.subthread_id}/set=set3`}
          sx={{
            backgroundColor: 'white', 
            width: 42, 
            height: 42,
            borderRadius: 99999,
            border: '1px solid lightgrey',
          }} 
        />
      </NavLink>
    </Box>
  )
}

export default SubthreadAvatar;