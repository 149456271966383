import { useEffect, useState } from "react";
import { Avatar, Box, Container, Flex, Paragraph, Text } from "theme-ui";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useUser } from "../../../contexts/user";


const Overview = () => {
  const {username} = useParams();
  const [active, setActive] = useState(null);
  const location = useLocation();
  const {error} = useUser();

  const handleLinkClick = (link) => {
    setActive(link);

  }

  useEffect(() => {
    const currentPath = location.pathname;
    const activeLink = links.find(link => link.to === currentPath);
    setActive(activeLink ? activeLink.name : 'Overview');

  }, [location]);

  const links = [
    { name: 'Overview', to: `/linkup/user/${username}` },
    { name: 'Posts', to: `/linkup/user/${username}/submitted` },
    { name: 'Comments', to: `/linkup/user/${username}/comments` },
  ];

  if (error) {
    return (
      <Container px={4}>
        <Text sx={{ color: 'red', fontWeight: 'bold', fontSize: '1.5rem', py: 4 }}>
          {error}
        </Text>
      </Container>
    );
  }

  return (
    
    <Container px={4}>
      <Flex sx={{alignItems: 'flex-end', gap: 2, py: '1rem', px: '2rem' }}>
        
          <Avatar 
            
            src={`https://robohash.org/${username}/set=set1`}
            sx={{
  
              backgroundColor: 'white', 
              width: 200, 
              height: 200,
              borderRadius: 10,
              border: '1px solid lightgrey',
              
            }} 
          />
          <Box sx={{p: 2}}>
            <Paragraph
              sx={{
                fontWeight: 'bold',
                fontSize: '2rem',
                lineHeight: 1,
              }}
            >{username}</Paragraph>
            <Paragraph sx={{color:'lightgrey', fontSize: '1rem'}}>u/{username}</Paragraph>
          </Box>
          <Box>
          </Box>
      </Flex>
      <Flex sx={{ 
        textTransform: 'uppercase', 
        letterSpacing: '0.1em',
        width: '100%',
        marginBottom: "1rem",
        borderBottom: '1px solid #d3d3d3',
        py: '1rem',
        px: '2rem',
      }}>
        {links.map((link) => (
          <Link
            to={link.to} 
            key={link.name}
            m={2} 
            style={{textDecoration: 'none'}}
            onClick={() => handleLinkClick(link.name)}
          >
            <Flex
              sx={{
                p: 2,
                maxWidth: '100%',
                borderRadius: '0.5rem',
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem 1rem',
                backgroundColor: active === link.name ? '#f0f0f0' : 'transparent',
                color: active === link.name ? 'black' : 'text',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: active === link.name ? '#f0f0f0' : '#e0e0e0',
                  color: 'black',
                }
              }}
            >
              <Text>{link.name}</Text>
            </Flex>
          </Link>
        ))}
      </Flex>
      <Outlet />
      
    </Container>
    
  )
}

export default Overview;