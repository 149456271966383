import { useContext, useEffect, useState } from "react";
import { BsSearch, BsXCircle } from "react-icons/bs";
import { Box, Button, Flex, Input, Link, useColorMode } from "theme-ui";
import { useAlert } from "../../contexts/alert";
import { AuthContext } from "../../contexts/auth";

const CreatePostSubthreadSelector = ({ onSearchResults, setSubthreadId, setIsSearching, onSubthreadSelect }) => {
  const { jwtToken } = useContext(AuthContext);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [timer, setTimer] = useState(null);
  const { showAlert } = useAlert();
  const [colorMode] = useColorMode();

  const handleSearch = async () => {
    if (query.length > 0) {
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", `Bearer ${jwtToken}`)
        const requestOptions = {
          headers: headers,
        }
        const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/find_sub?q=${encodeURIComponent(query)}`, requestOptions);
        const data = await response.json();
        // console.log(data);
        if (data.error) {
          showAlert(data.error.message);
        }
        setSuggestions(data.subthreads || []);

      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
    setShowSuggestions(true);
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setQuery('');
    setShowSuggestions(false);
    if (typeof onSearchResults === 'function') {
      onSearchResults([]); // Clear search results in parent component
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    setQuery(suggestion.id); 
    setShowSuggestions(false);
    setSubthreadId(suggestion.id);
    onSubthreadSelect(suggestion.id);
    setIsSearching(false);
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(() => handleSearch(query), 3000)); // 3 seconds delay

    return () => clearTimeout(timer);
  }, [query]);
  return (
    <Flex 
      sx={{ 
        alignItems: 'center', 
        position: 'relative',
        width: '30%',
        maxWidth: '40rem' 
      }}
    >
      <BsSearch style={{
        position: 'absolute',
        left: '8px',
        top: '47%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        color: 'black'
      }}/>
      <Input
        sx={{
          p: '8px 8px 8px 30px',
          borderRadius: '4px',
          maxWidth: '40rem',
          backgroundColor: '#f0f0f0',
          color: 'black',
          borderColor: 'transparent',
          '&:focus': {
            outline: 'none',
            borderColor: 'primary',
            color: 'black'
          },
        }}
        placeholder="Search..."
        value={query}
        onChange={handleChange}
        onFocus={() => setShowSuggestions(true)}
      />
      {query && (
        <Button 
          sx={{ alignItems:'center', position: 'absolute', right: '0.1rem', top: '1.25rem',  transform: 'translateY(-50%)', background: 'transparent', border: 'none', cursor: 'pointer', color: 'black'}}
          onClick={handleClear}
          
        >
          <BsXCircle />
        </Button>
      )}
      {showSuggestions && suggestions.length > 0 && (
        <Box sx={{ 
          position: 'absolute', 
          top: '100%', left: '0', right: '0', 
          bg: 'background', borderRadius: '4px', 
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', 
          maxHeight: '200px', overflowY: 'auto', zIndex: 1000,
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`, 
          textTransform: 'none',
          letterSpacing: 'normal', 
          }}>
          {suggestions.map((suggestion) => (
            // <SubHeader key={suggestion.id}/>
            <Box 
              key={suggestion.id} 
              sx={{ p: 2, cursor: 'pointer', backgroundColor: colorMode === 'dark' ? '#e0e0e0' : 'inherit', color: 'black', '&:hover': { backgroundColor: '#f0f0f0', color: 'black' } }} 
              onMouseDown={() => handleSelectSuggestion(suggestion)}>
              <Link>t/{suggestion.id}</Link>
            </Box>
          ))}
        </Box>
      )}
    </Flex>
  )
}

export default CreatePostSubthreadSelector;