import React, { useState, useEffect } from 'react';
import { getFromCache, saveToCache } from '../../utils/cache';


const API_KEY = `${process.env.REACT_APP_TENOR}`; 
const TENOR_API_URL = 'https://tenor.googleapis.com/v2/search';

const GifPicker = ({ onSelect }) => {
  const [gifs, setGifs] = useState([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGifsFromAPI = async () => {
      if (query.trim() === '') {
        setGifs([]);
        return;
      }

      const cacheKey = `gifs_${query}`;
      const cachedGifs = getFromCache(cacheKey);

      if (cachedGifs) {
        setGifs(cachedGifs);
        return;
      }

      setLoading(true);
      try {
        // `${TENOR_API_URL}?q=${query}&key=${API_KEY}&limit=10`
        const response = await fetch(TENOR_API_URL+`?q=${query}&key=`+API_KEY+`&limit=10`);
        const data = await response.json();
        // console.log(data);
        const gifUrls = data.results.map(result => { 
          const gifUrl = result.media_formats.gif.url;
          const [width, height] = result.media_formats.gif.dims;

          return {url: gifUrl, width, height};
        });
        saveToCache(cacheKey, gifUrls);
        setGifs(gifUrls);
      } catch (error) {
        console.error('Error fetching GIFs from gif picker:', error);
        setGifs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchGifsFromAPI();
  }, [query]);

  return (
    <div className='gif-picker'>
      <input
        type='text'
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder='Search for GIFs'
      />
      {loading && <p>Loading...</p>}
      <div className='gif-grid'>
        {gifs.length > 0 ? (
          gifs.map((gif, index) => {
            const {url, width, height} = gif;

            const targetWidth = 25;
            const aspectRatio = height / width;
            const newWidth = `${targetWidth}%`;
            const newHeight = `calc(${targetWidth}% * ${aspectRatio})`;

            return (
            <img
              key={index}
              src={url}
              alt={`gif-${index}`}
              style={{ width: newWidth, height: newHeight, cursor: 'pointer', borderRadius: '5px' }}
              onClick={() => onSelect(url)}
            />
          )})
        ) : (
          !loading && <p>No GIFs found</p>
        )}
      </div>
    </div>
  );
};

export default GifPicker;
