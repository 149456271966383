import { FaBold, FaCode, FaHeading, FaItalic, FaLink, FaQuoteRight, FaRegFileImage, FaRegSmile, FaStrikethrough, FaUnderline, FaUndo, FaUpload } from "react-icons/fa";
import { Box, Button, Flex, Input, Select } from "theme-ui";

import { useState } from "react";


import ErrorBoundary from '../errors/error-boundary';
import DOMPurify from 'dompurify';
import EmojiPickerComponent from './emoji-picker';

import Quill from 'quill';
import GifPicker from './gif-picker';
import ImageUploader from './image-uploader';
import Modal from '../../modal/modal';

// const gf = new GiphyFetch(`${process.env.REACT_APP_GIPHY}`);



const QuillEditorButtons = ({quillRef, setContent}) => {
  const [showHeaderDropdown, setShowHeaderDropdown] = useState(false);
  const [showTableDropdown, setShowTableDropdown] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  // const [setGifUrl] = useState('');
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [headerLevel, setHeaderLevel] = useState(1);
  const [tableRows, setTableRows] = useState(2);
  const [tableCols, setTableCols] = useState(2);
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [urlTitle, setUrlTitle] = useState('');

  
  // const fetchGifs = async (query) => {
  //   try {
  //     const result = await gf.search(query, {limit});
  //     setGifs(result.data);
  //   } catch (error) {
  //     console.error('Error fetching GIFs:', error);
  //   }
  // };

  const handleGifSelect = (gifUrl) => {
    // console.log('Selected GIF URL:', gifUrl);
    const editor = quillRef.current.getEditor();
    if (editor) {
      insertGif(editor, gifUrl);
      setShowGifPicker(false);
    }
  };

  // const applyListFormat = (listType) => {
  //   const editor = quillRef.current.__quill;
  //   editor.focus();
  //   const range = editor.getSelection(true);
  //   if (range) {
  //     editor.format('list', listType, Quill.sources.USER);
  //     // editor.format('list', listType, Quill.sources.USER);
  //   }
  // }

  const insertGif = (editor, gifUrl) => {
    editor.focus();
    const range = editor.getSelection(true);
    // console.log('Inserting GIF at range:', range);
  
    if (range) {
      editor.insertEmbed(range.index, 'image', gifUrl);
      editor.setSelection(range.index + 1);
      setTimeout(() => {
        const images = document.querySelectorAll('.ql-editor img');
        images.forEach(img => {
          if (img.src === gifUrl) {
            img.style.borderRadius = '5px';
            img.style.width = '25%';
            img.style.height = 'auto';
          }
        });
      }, 0);
    }
  };

  // const onGifClick = (gif, e) => {
  //   e.preventDefault();
  //   const gifUrl = gif.images.fixed_height.url;
  //   const textarea = document.querySelector('.comment-textarea');
  //   if (textarea) {
  //     const start = textarea.selectionStart;
  //     const end = textarea.selectionEnd;
  //     const text = textarea.value;
  //     const before = text.substring(0, start);
  //     const after = text.substring(end);
  //     setContent(`${before}![GIF](${gifUrl})${after}`);
  //     textarea.focus();
  //   }
  //   setGifUrl(gifUrl);
  //   setShowGifPicker(false);
  // };
  
  const handleUrlChange = (e) => setUrl(e.target.value);

  const handleApplyUrl = () => {
    const editor = quillRef.current.getEditor();
    if (editor) {
      const range = editor.getSelection(true);
      if (range) {
        const selectedText = editor.getText(range.index, range.length);
        const linkText = selectedText !== '' ? selectedText : urlTitle;
        const sanitizedUrl = sanitizeUrl(url);
        // const newSelectedText = `[${linkText}](${sanitizedUrl})`;
        
        editor.deleteText(range.index, range.length);
        editor.insertText(range.index, linkText, Quill.sources.USER);
        editor.formatText(range.index, linkText.length, {link: sanitizedUrl});
        setIsUrlModalOpen(false);
        setUrl('');
        setUrlTitle('');
      }
    }
  };
  

  const sanitizeUrl = (url) => {
    try {
      const cleanUrl = DOMPurify.sanitize(url);
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `http://${cleanUrl}`;
      }
      const newUrl = new URL(cleanUrl);
      return newUrl.href;
    } catch (e) {
      // If the URL is invalid, return an empty string or a placeholder
      return '';
    }
  };

  const applyMarkdown = (syntaxStart, syntaxEnd = syntaxStart) => {
    const textarea = document.querySelector('.comment-textarea');

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      var range = editor.getSelection(true);
      const selectedText = editor.getText(range.index, range.length);
      let newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;

      
      
      // Handle blockquotes
      switch (syntaxStart) {
        case '> ':
          
          // editor.deleteText(range.index, range.length);
          editor.format('blockquote', true, Quill.sources.USER);
          newSelectedText = selectedText.split('\n').map(line => `> ${line}`).join('\n');
          // editor.insertText(range.index, selectedText);

          break;
          
        case (syntaxStart.startsWith('#') ? syntaxStart : null):
          // editor.deleteText(range.index, range.length);
          editor.format('header', headerLevel, Quill.sources.USER);
          newSelectedText = selectedText.split('\n').map(line => `${syntaxStart}${line}`).join('\n');

          break;


  
        case '**':
          // editor.deleteText(range.index, range.length);
          editor.format('bold', true, Quill.sources.USER);
          break;
          
        case '_':
          editor.format('italic', true, Quill.sources.USER);

          break;
          
        case '~~':
          editor.format('strike', true, Quill.sources.USER);
          break;
  
        case '`':
          // editor.deleteText(range.index, range.length);
          editor.format('code', true, Quill.sources.USER);
        break;
        
        // case '[':
          
        //   const url = prompt('Enter the URL');
        //   const sanitizedUrl = sanitizeUrl(url);
        //   newSelectedText = `[${selectedText}](${sanitizedUrl})`;
        //   editor.format('link', Quill.sources.USER);
        //   break;
        
        case '<u>':
          // editor.deleteText(range.index, range.length);
          editor.format('underline', true, Quill.sources.USER);
          // editor.insertText(range.index, selectedText);
        break;
  
        default:
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
      }


    //  editor.deleteText(range.index, range.length);


    // editor.insertText(range.index, selectedText);

 
    editor.setSelection(range.index + newSelectedText.length, 0);
     
     
     // Force the editor to re-render
     
    // editor.update(); 
      
    editor.focus();
      
    } else if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const selectedText = text.substring(start, end);
      const after = text.substring(end);
      let newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;

      switch (syntaxStart) {
        case '> ':
          newSelectedText = selectedText.split('\n').map(line => `> ${line}`).join('\n');
          break;
          
        case (syntaxStart.startsWith('#') ? syntaxStart : null):
          newSelectedText = selectedText.split('\n').map(line => `${syntaxStart}${line}`).join('\n');
          break;
  
  
        case '**':
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
          
        case '_':
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
          
        case '~~':
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
  
          case '`':
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
          
          case '[':
            const url = prompt('Enter the URL');
            const sanitizedUrl = sanitizeUrl(url);
            newSelectedText = `[${selectedText}](${sanitizedUrl})`;
            break;
          case '<u>':
            newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
            break;
        default:
          newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
          break;
      }
      const newText = `${before}${newSelectedText}${after}`;

      setContent(newText);
      textarea.focus();
      textarea.value = newText;
      textarea.setSelectionRange(before.length + newSelectedText.length, before.length + newSelectedText.length);

    }
  };



  const insertHeader = () => {
    applyMarkdown('#'.repeat(headerLevel) + ' ');
    setShowHeaderDropdown(false);
  };

  const insertTable = () => {
    let tableSyntax = '| ' + 'Header | '.repeat(tableCols) + '\n';
    tableSyntax += '| ' + '--- | '.repeat(tableCols) + '\n';

    for (let i = 0; i < tableRows; i++) {
      tableSyntax += '| ' + 'Cell | '.repeat(tableCols) + '\n';
    }

    applyMarkdown(tableSyntax, '');
    setShowTableDropdown(false);
  };

  // const addEmoji = (emoji) => {
  //   const textarea = document.querySelector('.comment-textarea');

  //   if (textarea) {
  //     const start = textarea.selectionStart;
  //     const end = textarea.selectionEnd;
  //     const text = textarea.value;
  //     const before = text.substring(0, start);
  //     const after = text.substring(end);
  //     const emojiString = emoji.native;
  //     setContent(`${before}${emojiString}${after}`);
  //     textarea.focus();
  //   }
  // }
  // const handleImageLoader = (syntaxStart, syntaxEnd = syntaxStart) => {
  //   const textarea = document.querySelector('.comment-textarea');
  //   if (quillRef?.current) {
  //     const editor = quillRef.current.__quill;
  //     const range = editor.getSelection(true);
  //     const selectedText = editor.getText(range.index, range.length);
  //     let newSelectedText = `${syntaxStart}${selectedText}${syntaxEnd}`;
  //     editor.format('uploader', Quill.sources.USER);
  //     editor.setSelection(range.index + newSelectedText.length, 0);
  //     editor.focus();
  //   }
  //   if (textarea) {
  //     setShowImageUploader(!showImageUploader)
  //   }
  // }

  const removeFormats = () => {

    if (quillRef?.current) {
      const editor = quillRef.current.getEditor();
      if (editor) {
        const range = editor.getSelection(true);
        if (range) {
          editor.removeFormat(range.index, range.length);
        }
      }
    }
  }

  return (
    <Flex sx={{mb:2, flexDirection: 'column'}}>
      <Flex sx={{alignContent: 'space-between', gap:'3'}}>
        <Box onClick={() => setShowHeaderDropdown(!showHeaderDropdown)}><FaHeading /></Box>
        <Box  onClick={()=> applyMarkdown('**', '**')}><FaBold/></Box>
        <Box onClick={()=> applyMarkdown('_')}><FaItalic/></Box>
        <Box onClick={()=> applyMarkdown('<u>', '</u>')}><FaUnderline/></Box>
        <Box onClick={()=> applyMarkdown('~~')}><FaStrikethrough/></Box>
        {/* <Box onClick={()=> applyListFormat('bullet')}><FaListUl/></Box>
        <Box onClick={()=> applyListFormat('ordered')}><FaListOl/></Box>
        <Box onClick={()=> applyListFormat('unchecked')}><FaTasks/></Box> */}
        <Box onClick={()=> applyMarkdown('`')}><FaCode/></Box>
        <Box onClick={() => applyMarkdown('> ')}><FaQuoteRight /></Box>
        {/* <Box onClick={() => setShowTableDropdown(!showTableDropdown)}><FaTable /></Box> */}
        <Box onClick={() => setIsUrlModalOpen(true)}><FaLink /></Box>
        <Box onClick={() => setShowImageUploader(!showImageUploader)}><FaUpload /></Box>
        <Box onClick={() => setShowEmojiPicker(!showEmojiPicker)}><FaRegSmile /></Box>
        <Box onClick={() => setShowGifPicker(!showGifPicker)}><FaRegFileImage /></Box>
        <Box onClick={() => removeFormats()}><FaUndo /></Box>
      </Flex>
      {showHeaderDropdown && (
        <Flex sx={{ alignItems: 'center', mt: 2 }}>
          <Select 
            value={headerLevel} 
            onChange={(e) => setHeaderLevel(e.target.value)}
            sx={{width: '4rem'}}
          >
            {[...Array(6)].map((_, i) => (
              <option key={i} value={i + 1}>{`H${i + 1}`}</option>
            ))}
          </Select>
          <Button onClick={insertHeader} sx={{ ml: 2 }}>Add</Button>
        </Flex>
      )}
      {showTableDropdown && (
        <Flex sx={{ alignItems: 'center', mt: 2 }}>
          <Input
            width={'25%'}
            placeholder="Rows"
            type="number"
            value={tableRows}
            onChange={(e) => setTableRows(Number(e.target.value))}
            min={1}
            sx={{width: '3rem'}}
          />
          <Input
            
            placeholder="Cols"
            type="number"
            value={tableCols}
            onChange={(e) => setTableCols(Number(e.target.value))}
            min={1}
            sx={{ ml: 2, width: '3rem' }}
          />
          <Button onClick={insertTable} sx={{ ml: 2 }}>Add Table</Button>
        </Flex>

      )}
      {showEmojiPicker && (
        <Box sx={{ mt: 2 }}>
          <ErrorBoundary>
            <EmojiPickerComponent quillRef={quillRef} setContent={setContent} />
          </ErrorBoundary>
          
        </Box>
      )}
      {showGifPicker && (
        <Box mt={2}>
          <div className='gif-picker-container'>
            <GifPicker onSelect={handleGifSelect} />
          </div>
        </Box>
        // <Box sx={{ mt: 2 }}>
        //   <form onSubmit={handleSearchSubmit}>
        //     <input
        //       type="text"
        //       value={searchTerm}
        //       onChange={handleSearchChange}
        //       placeholder="Search GIFs"
        //     />
        //     <button type="submit">Search</button>
        //   </form>
        //   <Grid
        //   fetchGifs={gifs}
        //   width={500}
        //   columns={6}
        //   gutter={6}
        //   onGifClick={onGifClick}
        // />
        // </Box>
    )}

    {showImageUploader && (
      <Box my={2}>
        <ImageUploader quillRef={quillRef} setShowImageUploader={setShowImageUploader}/>
      </Box>
      )}

      {isUrlModalOpen && (
        <Modal isOpen={isUrlModalOpen} onClose={() => setIsUrlModalOpen(false)}>
          
          <Box p={1} >
            <Input 
              mt={2}
              type="text"
              value={urlTitle}
              onChange={(e) => setUrlTitle(e.target.value)}
              placeholder='Title of URL'
            />
            <Input
              mt={2}
              type="text"
              value={url}
              onChange={handleUrlChange}
              placeholder="Enter the URL"
            />
            <Button onClick={handleApplyUrl} sx={{ mt: 2 }}>Apply</Button>
          </Box>
      </Modal>
      )}
    </Flex>
    
  )
}

export default QuillEditorButtons;