import { useContext, useEffect, useState } from "react";
import { BsCaretDownFill, BsCaretUpFill, BsPlus } from "react-icons/bs";
import { Avatar, Box, Flex, Text, Link } from "theme-ui";
import { AuthContext } from "../../../contexts/auth";
import {formatErrors} from "../../utils/auth/auth.utils";
import Modal from "../../modal/modal";
import CreateSubthreadForm from "../../form/create-subthread-form";
import { useSubthreads } from "../../../contexts/subthreads";
import { useNavigate } from "react-router-dom";


const SubthreadNavDropdown = () => {
  const [isOpen, setIsOpen] = useState(true);
  const {subthreads, setSubthreads} = useSubthreads();
  // const [subthreads, setSubthreads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  
  const { jwtToken } = useContext(AuthContext);

  const openModal = () => {
    setIsModalOpen(true);
  }

  const closeModal = () => {
    setIsModalOpen(false);
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    headers.append("Authorization", `Bearer ${jwtToken}`)
    const requestOptions = {
      method: 'GET',
      headers: headers,
      
    }
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthreads`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // console.log(data);
      if (data.error) {
        let errorMessage = '';

        if (typeof data.error.message === 'object') {
          errorMessage = formatErrors(data.error.message);
        } else if (typeof data.error.message === 'string') {
          errorMessage = data.error.message;
        } else {
          errorMessage = 'Unknown error occurred';
        }
        console.error(errorMessage);
      }
      setSubthreads(data.subthreads || []);
      
    })
    .catch((error) => {
      console.error("Fetch error:", error);
    })
  }, [jwtToken]);
  return (
    <>
      {jwtToken && (
    
      <Box sx={{
        py: '1rem', 
        position: 'relative', 
        borderBottom: '1px solid #d3d3d3',
      }}>

     
        <Flex onClick={toggleDropdown} 
        
          sx={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            alignItems: 'center',
            maxWidth: '100%',
            borderRadius:'0.5rem',
            padding: '0.5rem 1rem',
            justifyContent:'space-between',
            px:'2', 
            // width: "100%",
            // padding: 0,
            '&:hover': { 
              backgroundColor: '#f0f0f0',
              color: 'black'
            }
          }}
        >
          <Text>Subthreads</Text>
          {isOpen === true ? <BsCaretUpFill/> : <BsCaretDownFill/>}

        </Flex>

        {isOpen && (
          <Box sx={{mt: 2}}>
            <Box 
              sx={{
                
                px: 2,
                maxWidth: '100%',
                borderRadius:'0.5rem',
                padding: '0.3rem 0.5rem',


                '&:hover': {
                  bg: 'muted',
                  cursor: 'pointer',
                  backgroundColor: '#f0f0f0',
                  color: 'black'
                },
              }}
            >
              <Link
                onClick={openModal}
                sx={{
                  // display: 'block',
                  // textDecoration:'none',
                  // color: 'text',
                  
                }}
              >
                
                <Flex 
                  sx={{
                    alignItems: 'center',
                  }}
                >
                  <BsPlus
                    size={30}
                  />
                  <Text px={2} sx={{fontSize:'0.9rem'}}>Create a subthread</Text>
                </Flex>

                
              </Link>
            </Box>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
              <CreateSubthreadForm closeModal={closeModal} />
            </Modal>
            {subthreads.map((subthread) => (
              <Box
                key={subthread.id}
                sx={{
                  px: 2,
                  maxWidth: '100%',
                  borderRadius:'0.5rem',
                  padding: '0.3rem 0.5rem',


                  '&:hover': {
                    bg: 'muted',
                    cursor: 'pointer',
                    backgroundColor: '#f0f0f0',
                    color: 'black'
                  },
                }}
                onClick={() => navigate(`/linkup/t/${subthread.id}`)}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <Avatar
                      src={subthread.avatar_url ? subthread.avatar_url: `https://robohash.org/${subthread.id}/set=set3`} 
                      size={30}
                      sx={{
                        borderRadius: 99999,
                        border: '1px solid lightgrey',
                        width: '30px',
                        height: '30px',
                        objectFit: 'cover'
                      }}  
                    />
                    <Text px={2} sx={{fontSize:'0.9rem'}} >t/{subthread.id}</Text>
                  </Flex>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    )}
    </>
  )
}

export default SubthreadNavDropdown;