import { useState } from "react"
import { useAlert } from "../../../contexts/alert";
import { Container, Heading, Box, Button, Alert } from "theme-ui";
import FormInput from "../../form/form-input";
import {formatErrors} from "../../utils/auth/auth.utils";
import { useLocation, useNavigate } from "react-router-dom";



const LinkupResetPassword = () => {
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const { showAlert, hideAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== verifyPassword) {
      setValidationError('Passwords do not match!');
      return;
    }

    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const hash = query.get('hash');

    if (!email || !hash) {
      showAlert('Invalid reset password link', 'error');
      return;
    }

    const payload = {
      password,
      email,
      hash,
      type: 'linkup',
    };

    const headers = new Headers();
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    };

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/reset-password`, requestOptions)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      // console.log(data);
      if (data.error){
        let errorMessage = '';

        if (typeof data.error.message === 'object') {
          errorMessage = formatErrors(data.error.message);
        } else if (typeof data.error.message === 'string') {
          errorMessage = data.error.message;
        } else {
          errorMessage = 'Unknown error occurred';
        }
        showAlert(errorMessage, 'alert');
      } else {
        const updatedAt = new Date(data.user.updated_at).toLocaleString(undefined, {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        showAlert(`Password reset successful on ${updatedAt}`, 'success');
        setTimeout(() => {
          navigate('/linkup/login')
        }, 1500);
      }
    })
    .catch(error => {
      console.error("Error resetting password:", error);
      showAlert('Failed to reset password. Please try again later.', 'error');
    });
  };

  return (
    <Container sx={{ mt: 5, width: '20em' }}>
      <Heading as={"h2"} sx={{ textAlign: 'center', mb: 4}}>
        Reset Password
      </Heading>
      {validationError && <Alert variant="error">{validationError}</Alert>}
      <Box as="form" onSubmit={handleSubmit} noValidate>
        <Box sx={{mb:3}}>
          <FormInput 
            title="Password"
            type="password"
            name="password"
            value={password}
            autoComplete="password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </Box>
        <Box sx={{mb:3}}>
          <FormInput 
            title="Verify Password"
            type="password"
            name="verify-password"
            value={verifyPassword}
            autoComplete="verify-password"
            onChange={(e) => setVerifyPassword(e.target.value)}
            required
          />
        </Box>
        <Button variant="primary" type="submit">Reset Password</Button>
      </Box>
    </Container>
  )
}

export default LinkupResetPassword;