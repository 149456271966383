import { Avatar, Box, Button, Card, Flex, Grid, Heading, Label, Paragraph, Progress, Text } from "theme-ui";
import FormInput from "./form-input";
import { useContext, useEffect, useState } from "react";
import FormTextarea from "./form-textarea";
import { AuthContext } from "../../contexts/auth";
import { useAlert } from "../../contexts/alert";
import { useSubthreads } from "../../contexts/subthreads";
import AvatarUpload from "./avatar-upload";
import SubthreadHeaderUpload from "./SubthreadHeaderUpload";



const CreateSubthreadForm = ({closeModal}) => {
  const [subthreadID, setSubthreadID] = useState("");
  const [subthreadName, setSubthreadName] = useState("");
  const [subthreadDescription, setSubthreadDescription] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [headerImgUrl, setHeaderImgUrl] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const {jwtToken} = useContext(AuthContext);
  const { showAlert } = useAlert();
  const { subthreads, setSubthreads} = useSubthreads();

  const [subthreadExists, setSubthreadExists] = useState(false);
  const [check, setCheck] = useState(false);

  const handleSubthreadIDChange = (e) => {
    const value = e.target.value;
    const noSpaceFormat = value.replace(/\s/g, "");
    setSubthreadID(noSpaceFormat);
  }

  const handleNextStep = () => {
    if (subthreadExists || subthreadID.length < 1 || subthreadName.length < 1 || subthreadDescription.length < 1) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    const checkSubthreadID = () => {
      if (subthreadID.length > 0)  {
        setCheck(true);
      
        const requestOptions = {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${jwtToken}`
          }
        }
        fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthread/check/${subthreadID}`, requestOptions)
        .then((resp) => {
          return resp.json();
        })
        .then((data) => {
          setSubthreadExists(data);
          setCheck(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setCheck(false);
        })
      }
    }
    const debounce = setTimeout(checkSubthreadID, 3000);
    return () => clearTimeout(debounce);
  }, [subthreadID, jwtToken]);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    

    if (subthreadExists || subthreadID.length < 1 || subthreadName.length < 1 || subthreadDescription.length < 1) {
      return;
    }

    let payload = {
      avatar_url: avatarUrl,
      header_image_url: headerImgUrl,
      id: subthreadID,
      name: subthreadName,
      description: subthreadDescription,
    }

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${jwtToken}`
      },
      body: JSON.stringify(payload),
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthread/submit`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      if (data.error) {
          console.error('Unknown error occurred');
        } else {
          setSubthreads([...subthreads, data.subthread]);
          setSubthreadID('');
          setSubthreadName('');
          setSubthreadDescription('');
          closeModal();
          showAlert(`t/${data.subthread.id} has been successfully created`, 'success');
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    })
  }
  const idLimitColor = subthreadID.length > 25 || subthreadExists ? 'red' : 'gray'
  const nameLimitColor = subthreadName.length > 25 ? 'red' : 'gray'
  const descLimitColor = subthreadDescription.length > 500 ? 'red' : 'gray'
  
  return (
    <Box>
      <Heading as={'h3'}>Tell us about your subthread</Heading>
      <Paragraph sx={{fontSize:'0.9rem'}}>A name and description that lets users know what this subthread is about</Paragraph>
      
      <Box as="form" mt={3} onSubmit={handleSubmit} >
        {currentStep === 1 && (
          <>
          
              <Grid gap={4} columns={[0,0,0,1, '3fr 2fr']} sx={{alignItems:'stretch'}}>
                <Box>
                  <Flex
                    sx={{alignContent: 'center', flexDirection: 'column'}}
                  >
                    <Label>Subthread Avatar</Label>
                    <Box mb={1}>
                      <AvatarUpload setAvatarUrl={setAvatarUrl} />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Text sx={{fontSize: '0.75rem', color: idLimitColor, ml: 'auto', wordWrap: 'break-word'}}>{avatarUrl || 'No avatar selected'}</Text>
                      </Box>
                    </Box>
                    <FormInput 
                      title="Subthread ID"
                      type="subthread-id"
                      name="subthread-id"
                      autoComplete="subthread-new"
                      value={subthreadID}
                      onChange={handleSubthreadIDChange}
                      sx={{width:'100%'}}
                    />
                    {check && <Text sx={{mt: -3, fontSize: '0.75rem'}}>Checking...</Text>}
                    {subthreadExists && subthreadID.length !== 0 && <Text sx={{mt: -3, fontSize: '0.75rem', color: idLimitColor}}>Subthread ID already exists.</Text>}
                    {subthreadID.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: idLimitColor}}>Subthread ID must not be blank.</Text>}
                    <Text 
                      sx={{ 
                        mt: -3,
                        ml: 'auto',
                        fontSize: '0.75rem', 
                        color: idLimitColor, 
                        }}
                    >{subthreadID.length}/25
                    </Text>
                  </Flex>
                  <Flex
                    sx={{alignContent: 'center', flexDirection: 'column'}}
                  >
                    <FormInput 
                      title="Subthread Name"
                      type="subthread-name"
                      name="subthread-name"
                      autoComplete="subthread-new"
                      value={subthreadName}
                      onChange={(e) => setSubthreadName(e.target.value)}
                      sx={{width:'100%'}}
                    />
                    {subthreadName.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: nameLimitColor}}>Subthread name must not be blank.</Text>}
                    <Text 
                      sx={{ 
                        mt: -3,
                        ml: 'auto',
                        fontSize: '0.75rem', 
                        color: nameLimitColor, 
                        }}
                    >{subthreadName.length}/25
                    </Text>
                  </Flex>
                  <Flex
                    sx={{alignContent: 'center', flexDirection: 'column'}}
                  >

                  <FormTextarea 
                    title="Description"
                    type="description"
                    name="description"
                    autoComplete="description-new"
                    value={subthreadDescription}
                    onChange={(e) => setSubthreadDescription(e.target.value)}
                    sx={{resize:'none'}}
                  />
                    {subthreadDescription.length < 1 && <Text sx={{mt: -3, fontSize: '0.75rem', color: descLimitColor}}>Subthread description must not be blank.</Text>}
                  <Text
                    sx={{ 
                      ml: 'auto',
                      fontSize: '0.75rem', 
                      color: descLimitColor, 
                      mt: -3,
                      }}
                  >{subthreadDescription.length}/500</Text>
                  </Flex>
                </Box>
                <Box>
                  <Card
                    className="card-container" 
                    p={3} 
                    sx={{
                      borderRadius: '18px', 
                      boxShadow: '0 2px 3px', 
                      height: '100%', 
                      display: 'flex', 
                      flexDirection: 'column', 
                      maxHeight: '15.3rem',
                      
                    }}>
                      <Box sx={{ overflowY: 'auto' }}>
                        {avatarUrl && (
                          <Box>
                            <Avatar src={avatarUrl} alt="Avatar Preview"
                              sx={{ width: "100px", height:"100px", objectFit:"cover", borderRadius: "50%"}}
                            />
                          </Box>
                        )}
                      <Heading as={"h4"} sx={{wordWrap: 'break-word'}}>
                        t/{subthreadID || 'subthreadID'}
                      </Heading>
                      <Text sx={{fontSize: '0.8rem'}}>1 member · 1 online</Text>
                      <Heading sx={{wordWrap: 'break-word', pt:2, fontSize: '0.9rem'}}>
                      {subthreadName || 'Subthread Name'}
                      </Heading>  
                        <Paragraph sx={{fontSize: '0.9rem', wordWrap: 'break-word'}}>
                          {subthreadDescription || 'Your subthread description'}
                        </Paragraph>
                      </Box>
                  </Card>
                </Box>
              </Grid>
            <Box sx={{pt:'3'}}>
              <Flex sx={{alignItems: 'center', justifyContent:'space-between'}}>
                <Box sx={{width: '50%'}}>
                  <Progress max={2} value={1} />
                </Box>
      
                  <Button
                    type="button"
                    onClick={closeModal}
                    sx={{
                      ml:'auto', 
                      mr:'2', 
                      backgroundColor:'lightgray', 
                      color:'black',
                      cursor:'pointer'
                    }}
                  >Cancel</Button>
                  <Button
                    sx={{cursor:'pointer'}}
                    type="button"
                    onClick={handleNextStep}
                  >Next</Button>
              
              </Flex>
            </Box>
          </>
        )}

        {currentStep === 2 && (
          <>
            <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
              <Label>Subthread Header Image</Label>
              <SubthreadHeaderUpload setHeaderImgUrl={setHeaderImgUrl} />
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Text sx={{ fontSize: '0.75rem', color: 'gray', ml: 'auto', wordWrap: 'break-word' }}>{headerImgUrl || 'No header image selected'}</Text>
              </Box>
            </Flex>
            <Box sx={{pt:'3'}}>
              <Flex sx={{alignItems: 'center', justifyContent:'space-between'}}>
                <Box sx={{width: '50%'}}>
                  <Progress max={2} value={2} />
                </Box>
      
                  <Button
                    type="button"
                    onClick={handlePreviousStep}
                    sx={{
                      ml:'auto', 
                      mr:'2', 
                      backgroundColor:'lightgray', 
                      color:'black',
                      cursor:'pointer'
                    }}
                  >Back</Button>
                  <Button
                    sx={{cursor:'pointer'}}
                    type="submit"
                  >Submit</Button>
              
              </Flex>
            </Box>
          </>
        )}
        
      </Box>
    </Box>
  )
}

export default CreateSubthreadForm;