import { useContext, useState, useRef } from "react";
import { Box, Button, Flex, Grid } from "theme-ui";

import { AuthContext } from "../../contexts/auth";

import { useAlert } from "../../contexts/alert";


import QuillEditorButtons from "../linkup/editor/md-buttons";
import QuillEditorComponent from "../linkup/editor/QuillEditorComponent";
import { useComments } from "../../contexts/comment";
import { useUserActivity } from "../../contexts/userActivity";



const CommentForm = ({post, setIsExpanded}) => {
  const [content, setContent] = useState("");
  const {authUser} = useContext(AuthContext);
  const {showAlert} = useAlert();
  const {addComment} = useComments();
  const quillRef = useRef(null);
  const {logActivity} = useUserActivity();
 
  const handleCancel = () => {
    setIsExpanded(false);
    setContent('');
  }

  const handleSubmit = async () => {
    if (!content.trim()){
      showAlert('Comment must not be blank', 'alert')
      return;  
    }
    setIsExpanded(false);

    // const htmlContent = md.render(content);
    try {
      await addComment(content);
      
      const activityData = {
        user_id: authUser,
        event_type: 'comment',
        resource_id: post.url,
        sub_name: post.sub_name,
        activity_details: { 
          comment_content: content,
          timestamp: new Date().toISOString(),
       },
      };

      // console.log('Logging activity with payload:', activityData);
      await logActivity(activityData);

      setContent("");
    } catch (error) {
      showAlert("Something went wrong. Please try again later.", "alert");
      console.error(error);
    }

  }
 

   
  return (
    <>

      <QuillEditorComponent setContent={setContent} quillRef={quillRef}/>

      <Grid>
        <Box sx={{ mt: 2 }}>
          <QuillEditorButtons quillRef={quillRef} setContent={setContent} />
        </Box>
        <Box >
          <Flex sx={{ justifyContent: 'space-between', gap: 2 }}>
            <Button ml={'auto'} onClick={handleCancel}
              sx={{backgroundColor: 'lightgrey', color: 'inherit'}}
            >Cancel</Button>
            <Button variant="primary" onClick={handleSubmit}>Submit</Button>
          </Flex>
        </Box>
      </Grid>
    </>
  )
}

export default CommentForm;