import { useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Flex, Grid } from "theme-ui";
import { AuthContext } from "../../contexts/auth";
import { useAlert } from "../../contexts/alert";
import QuillEditorComponent from "../linkup/editor/QuillEditorComponent";
import QuillEditorButtons from "../linkup/editor/md-buttons";
import { useUserActivity } from "../../contexts/userActivity";
import { useComments } from "../../contexts/comment";


const ReplyForm = ({parentId, post, onReplySubmitted, toggleShowReplyForm}) => {
  const [content, setContent] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const pickerRef = useRef(null);
  const emojiRef = useRef(null);
  const quillRef = useRef(null);
  
  const {authUser} = useContext(AuthContext);
  const {showAlert} = useAlert();
  const {logActivity} = useUserActivity();
  const { addReply } = useComments();
  
  const handleClickOutside = (e) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target) && 
    emojiRef.current && !emojiRef.current.contains(e.target)){
      setShowEmojiPicker(false);
    }
  }

  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showEmojiPicker]);


  const handleCancel = () => {
    toggleShowReplyForm();
    setContent('');
  }


  const handleSubmit = async () => {
    if (!content.trim()){
      showAlert('Comment must not be blank.', 'alert');
      return;  
    }

    if (!authUser) {
      showAlert('Must be logged in to reply.', 'alert');
      return;
    }
    try {
      const reply = await addReply(parentId, content);
      // console.log('Reply submitted:', reply.comment);
      
      if (onReplySubmitted) onReplySubmitted();
      const activityData = {
        user_id: authUser,
        event_type: 'reply',
        resource_id: post.url,
        sub_name: post.sub_name,
        activity_details: { 
          comment_id: reply.comment.id,
          comment_parent_id: parentId || null,
          comment_content: content,
          timestamp: new Date().toISOString(),
        },
      };
      logActivity(activityData);
      setContent("");
    } catch (error) {
      showAlert('Something went wrong. Please try again later.', 'alert')
      console.error(error);
    }
  }
   
  return (
    <>
      
      {/* {!markdownVisible 
        ? 
        <MarkdownInput
          quillRef={quillRef}
          content={content}
          setContent={setContent}
          className="comment-textarea"
          name="content"
          value={content}
          onChange={handleTextChange}
          placeholder="Write your comment..."
          sx={{ width: '100%', mb: 2 }}
        /> :
        
        <Textarea 
        content={content}
        setContent={setContent}
        className="comment-textarea"
        name="content"
        value={content}
        onChange={handleTextChange}
        placeholder="Write your comment..."
        sx={{ width: '100%', mb: 2 }}
        />
      } */}
      {/* You can add Markdown options here */}
      {/* <Box sx={{ mb: 2 }}>
      </Box> */}
        {/* Markdown options component or buttons */}
              <Box >
                <QuillEditorComponent 
                  
                  quillRef={quillRef} setContent={setContent}/>
              </Box>
        <Grid>
          <Box sx={{fontSize: '0.9rem', mt: 2}}>
            <Flex sx={{ justifyContent: 'space-between', alignItems:'center', gap: 2 }}>
                <Box>
                  <QuillEditorButtons quillRef={quillRef} setContent={setContent}/>
                </Box>
              {/* <Box className="toolbar-toggle" onClick={handleToggleToolbar}>
                {markdownVisible ? <FaFont /> : <FaEdit />}
              </Box> */}
              {/* <Box ml={-2}>
                <button 
                  className="ql-emoji" 
                  ref={emojiRef}
                  onClick={toggleShowEmojiPicker} 
                  style={{display:'flex', alignItems: 'center'}}
                >
                  <FaRegSmile />
                </button>
              </Box>
              <Box ml={-3}>
                <button 
                  className="ql-gif" 
                  onClick={() => setShowGifPicker(prev=>!prev)} 
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <FaRegFileImage />
                </button>
              </Box>
              <Box ml={-3}>
                <button 
                  className="ql-image" 
                  onClick={() => setShowGifPicker(prev=>!prev)} 
                  style={{display: 'flex', alignItems: 'center'}}
                >
                  <FaUpload />
                </button>
              </Box> */}
              <Button ml={'auto'} onClick={handleCancel}
                sx={{backgroundColor: 'lightgrey', color: 'inherit'}}
                >Cancel</Button>
              <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Flex>
            
                
          </Box>

        </Grid>

    </>
  )
}

export default ReplyForm;