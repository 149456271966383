import { Box, Button, Container, Heading } from "theme-ui"
import FormInput from "../../form/form-input"
import {formatErrors} from "../../utils/auth/auth.utils";
import { useAlert } from "../../../contexts/alert";
import { useState } from "react";


const LinkupForgotPassword = () => {
  const { showAlert, hideAlert } = useAlert();
  const [ email, setEmail ] = useState("");
  
  const handleSubmit = (e) => {
    e.preventDefault();
    
    let payload = {
      email: email,
      type: "linkup",
    }
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    const requestOptions = {
      
      method: "POST",
      headers: headers,
      
      body: JSON.stringify(payload),
    }
    
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/forgot-password`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // console.log(data);
      if (data.error){
        let errorMessage = '';

        if (typeof data.error.message === 'object') {
          errorMessage = formatErrors(data.error.message);
        } else if (typeof data.error.message === 'string') {
          errorMessage = data.error.message;
        } else {
          errorMessage = 'Unknown error occurred';
        }
        showAlert(errorMessage, 'alert');
      } else {
        showAlert("Password reset has been sent to your email.", 'success');
      }

    })
    .catch(err => {
      showAlert(err.message, 'alert');
      
    })
  }
  return (
    <Container mt={6} sx={{width: '20em'}}>
      <Heading as={"h2"} mb={3}>
        Forgot Password
      </Heading>
      <Box as="form" onSubmit={handleSubmit} mb={3}>
        <FormInput 
          title="Email"
          type="email"
          name="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button type="submit" sx={{cursor:'pointer'}}>Send Reset Password Link</Button>        
      </Box>
    </Container>
  )
}

export default LinkupForgotPassword;