import { Box } from "theme-ui";
import SubthreadInfo from "../subthreads/subthread-info-subscribe.component";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import RecentPosts from "../posts/RecentPosts";
import { AuthContext } from "../../../contexts/auth";


const RightContent = () => {
  const {subname} = useParams();
  const {jwtToken, authUser} = useContext(AuthContext);

  
  // console.log("jwtToken:", jwtToken, "authUser:", authUser);
  if (!authUser) {
    return null;
  }

  let content;
  if (subname && authUser) {
    content = 
    <>
      <SubthreadInfo subname={subname} />
      <RecentPosts />
    </>
  } else {
    
    content = <RecentPosts />;
    
  }


  return(
    <Box
      
      sx={{
        m: 2,
        backgroundColor: '#F0F0F0',
        borderRadius: '15px',
        maxWidth: '100%',
        padding: '1rem',
        color: 'black',
      }}
    >
    
    {content}  

    

    </Box>

  )
}

export default RightContent;
