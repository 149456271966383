import { useEffect, useState } from "react";
import { Button, Paragraph, Flex } from 'theme-ui';
import { CloudinaryContext, Image as CloudinaryImage } from "cloudinary-react";

const SubthreadHeaderUpload = ({ setHeaderImgUrl }) => {
  const [headerPreviewUrl, setHeaderPreviewUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  // Load Cloudinary widget script
  useEffect(() => {
    const existingScript = document.querySelector(`script[src="https://widget.cloudinary.com/v2.0/global/all.js"]`);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.async = true;
      script.onload = () => setIsScriptLoaded(true);
      script.onerror = () => setError('Failed to load Cloudinary script.');
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    } else {
      setIsScriptLoaded(true);
    }
  }, []);

  // Handle opening Cloudinary upload widget
  const openWidget = (e) => {
    e.preventDefault();

    if (isScriptLoaded && window.cloudinary) {
      const widget = window.cloudinary.createUploadWidget(
        {
          cloudName: `${process.env.REACT_APP_CLOUD_NAME}`,
          uploadPreset: `${process.env.REACT_APP_UPLOAD_PRESET}`,
          sources: ['local', 'url'],
          showAdvancedOptions: false,
          cropping: true, // Enable cropping for avatar images
          croppingAspectRatio: 16 / 9,
          croppingCoordinatesMode: 'custom', // Let the user adjust the cropping
          showSkipCropButton: false,
          theme: 'minimal',
          maxImageWidth: 1920,
          maxImageHeight: 1080,
          maxFileSize: 4194304, // Limit size to 4MB
          transformation: [
            { width: 1200, height: 675, crop: 'limit' }, 
            // { width: 200, height: 200, crop: 'thumb', gravity: 'face' }, // Avatar-specific transformation
            { quality: 'auto', fetch_format: 'auto' },
          ],
          folder: "header-img",
          clientAllowedFormats: ['png', 'jpeg', 'jpg', 'jfif', 'webp'],
        },
        (error, result) => {
          if (error) {
            setError('Upload failed.');
          } else if (result?.event === 'success') {
            const url = result.info.secure_url;
            setHeaderPreviewUrl(url);
            setHeaderImgUrl(url); 
          }
        }
      );
      widget.open();
    } else {
      setError('Cloudinary script not loaded');
    }
  };

  return (
    <CloudinaryContext cloudName={`${process.env.REACT_APP_CLOUD_NAME}`}>
      <Flex sx={{ justifyContent: 'space-between', mt: 2 }}>
        <Button onClick={openWidget}>Choose Header Image</Button>
      </Flex>
      {error && <Paragraph sx={{ color: 'red' }}>{error}</Paragraph>}
      {headerPreviewUrl && (
        <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 2, fontSize: '0.75rem', color: 'gray'}}>
          <CloudinaryImage publicId={headerPreviewUrl} alt="Header Preview" width="100" style={{ borderRadius: '8px' }} />
        </Flex>
      )}
      
    </CloudinaryContext>
  );
};

export default SubthreadHeaderUpload;
