import { Box } from "theme-ui";
import CommentInteract from "../pills/comment-interact.component";
import { useEffect, useState } from "react";
import CommentAvatar from "../../headers/comment-avatar";
import CommentHeaderInfo from "../../headers/comment-headerinfo";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";


const Comment = ({ post, comment, replies }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = (e) =>{
    e.preventDefault();
    setIsCollapsed(isCollapsed => !isCollapsed);
  }

  useEffect(() => {
    const iconElement = document.querySelector(".comment-author");
    if (iconElement) {
      const iconHeight = iconElement.offsetHeight * 0.8;
      document.documentElement.style.setProperty("--icon-height", `${iconHeight}px`)
    }

    const container = document.querySelector('.markdown-content');
    if (container) {
      const imgs = container.querySelectorAll('img');
      imgs.forEach(img => {
        const src = img.getAttribute('src');
        if (src && src.endsWith('.gif')) {
          img.style.width = '25%';
          img.style.borderRadius = '5px';
        }
      });
    }
  }, [comment.content]);

  return (
    <Box className="comment-box" mt={2}>
      <Box className={`comment-author dark-mode ${!comment.replies || comment.replies.length === 0 ? 'no-children' : ''}`}>
        <Box sx={{fontSize: '0.9em', alignItems: 'center', display: 'flex'}}>
          
          <Box>
            <CommentAvatar comment={comment}/>
          </Box>
          <Box px={2} >
            <CommentHeaderInfo comment={comment} post={post}/>

          </Box>
        
        </Box>
        <Box my={-2} sx={{pl: 5}}>
          <Box >
            <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: ({node, ...props}) => {
                  return props.src && props.src.endsWith('.gif') ? (
                    <img {...props} style={{width: '25%', borderRadius: '5px'}} alt="" />
                  ) : (
                    <img {...props} alt="comment" />
                  );
                }
              }}
            >{comment.content}</ReactMarkdown>  
          </Box>
        </Box>
        <Box>
          <CommentInteract sx={{pt: 2}} post={post} isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} comment={comment} />
        </Box>
      </Box>
        {!isCollapsed && comment.replies && comment.replies.length > 0 && (
          <Box sx={{ml: 3}}>
            {Array.isArray(comment.replies) && comment.replies.map(reply => (
              <Comment key={reply.id} comment={reply} post={post}/>
            ))}
          </Box>
        )}
    </Box>
  )
}

export default Comment;
