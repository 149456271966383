import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  let { subname, pid, slug } = useParams();

  const fetchPost = async () => {
    if (!subname || !pid || !slug) return;
    try {
      // console.log(`Fetching post with ${subname}, ${pid}, ${slug}`);
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      
      const requestOptions = {
        method: "GET",
        headers: headers
      }
      const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/${slug}`, requestOptions);
      if (!response.ok) {
        throw new Error("Failed to fetch post");
      }
      const data = await response.json();
      setPost(data.post);
    } catch (err) {
      setError(err.message);
    }
  };
 

  useEffect(() => {
    fetchPost();
  }, [subname, pid, slug]);

  return (
    <PostContext.Provider value={{ post, fetchPost, error }}>
      {children}
    </PostContext.Provider>
  );
};

export const usePost = () => useContext(PostContext);