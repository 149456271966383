import { Avatar, Box, Flex, Link, Text } from "theme-ui";
import { formatDistanceToNow } from "date-fns";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import VotePill from "../../../components/linkup/pills/votes-pill.component";
import CommentPill from "../../../components/linkup/pills/comments-pill.component";



const OverviewPosts = ({item}) => {

  return (
    <>
      <Flex  sx={{ alignItems: 'center', alignContent: 'space-between', mr:'auto', pt: '0.5rem'}}>
        <Box>
          <Link
            href={`/linkup/t/${item.subthread_id}`}
            sx={{
              fontWeight: 'bold',
              color: 'inherit', 
              textDecoration: 'none', 
              '&:hover': {
                color: 'primary'
            }}}
          >
            <Avatar 
              src={item.avatar_url ? item.avatar_url : `https://robohash.org/${item.subthread_id}/set=set3`}
              sx={{
                backgroundColor: 'white', 
                width: 30, 
                height: 30,
                borderRadius: 99999,
                border: '1px solid lightgrey',
              }} 
              alt={`${item.subthread_id} image`}
            />
          </Link>
        </Box>
        <Box sx={{fontSize: '14px'}}>
          <Link href={`/linkup/t/${item.subthread_id}`} 
            pl={2}
            sx={{
              fontWeight: 'bold',
              color: 'inherit', 
              textDecoration: 'none', 
              '&:hover': {
                color: 'primary'
              }}}
          >t/{item.subthread_id}</Link> • <Text sx={{fontStyle:'oblique', fontSize: '14px'}}>{`${formatDistanceToNow(new Date(item.created_at))} ago`}</Text>
        </Box>
      </Flex>
      <Box as={'h3'} >
        {item.post_title}
      </Box>
      <ReactMarkdown
        className={"markdown-content"}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          img: ({node, ...props}) => {
            return props.src && props.src.endsWith('.gif') ? (
              <img {...props} style={{width: '25%', borderRadius: '5px'}} alt=""/>
            ) : (
              <img {...props} alt=""/>
            );
          }
        }}
      >{item.post_content}</ReactMarkdown>
      <Flex sx={{mb:2 , alignItems: 'center'}}>
        <VotePill size={'1.5rem'} padding={'1'} post={item} />
        <CommentPill commentCount={item.comment_count} />
      </Flex>
    </>
  )
}

export default OverviewPosts;