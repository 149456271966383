import { useEffect, useState } from "react"
import { Box, Label, Select } from "theme-ui";
import SearchSubthreadsResults from "../posts/search-subthreads.component";


const Subthreads = ({subname, onSubthreadSelect}) => {
  // console.log(subname);
  // const {subname} = useParams();
  const [subthreadId, setSubthreadId] = useState("");
  const [subthreads] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error] = useState(null);
  
  // const calculatedWidth = `${subname.length * 8}px`;

  useEffect(() => {
    if (subname) {
      setSubthreadId(subname);
    }

  //   const fetchSubthreads = async () => {
  //     const headers = new Headers();
  //     headers.append("Content-Type", "application/json");
    
  //     const requestOptions = {
  //       method: "GET",
  //       headers: headers,
  //     }
  //     try {
  //       const resp = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthreads`, requestOptions)
  //       const data = await resp.json();
  //       if (data.subthreads && Array.isArray(data.subthreads)){
  //         setSubthreads(data.subthreads);
  //       } else {
  //         throw new Error('Unexpected data format');
  //       }
  //     } catch (err) {
  //       setError(err.message);
  //     };
      
  //   }
  //   fetchSubthreads();
  }, [subname]);

  const handleSelectionChange = (e) => {
    const selectedSubname = e.target.value;
    setSubthreadId(selectedSubname);
  }

  
  
  const toggleSearchSubthread = (e) => {
    e.preventDefault();
    setIsSearching(isSearching => !isSearching);
  }  
  
  
  
  if (error) {
    return <div>Error: {error}</div>
  }
  if (!Array.isArray(subthreads)) {
    return <div>No subthreads available</div>
  }

  return (
    <>

    <Box pb={2}>
      <Label>Subthread</Label>
      {!isSearching ? (
        <Select 
          defaultValue={subthreadId || ""} 
          onClick={toggleSearchSubthread}
          onChange={handleSelectionChange}
          sx={{width: '25%'}}
        > 
          <option value={subthreadId}>
            t/{subthreadId}
          </option>

        </Select>

      ) : (
        <SearchSubthreadsResults 
          setSubthreadId={setSubthreadId} 
          setIsSearching={setIsSearching}
          onSubthreadSelect={onSubthreadSelect}
        />
      )}
        
    </Box>
        
    </>
  )
}

export default Subthreads;