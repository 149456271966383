import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container, Flex, Heading, Paragraph } from "theme-ui";
import Dropdown from "../../../components/linkup/dropdown/dropdown.component";
import BackButton from "../../../components/linkup/button/back-button";
import HeaderTag from "../../../components/headers/header-tag.component";
import SubthreadAvatar from "../../../components/headers/subthread-avatar";
import HeaderInfoWithUsername from "../../../components/headers/header-info-username.component";
import InteractBar from "../../../components/linkup/pills/interact.component";
import CommentsList from "../../../components/linkup/comments/comments-list.component";
import AddComment from "../../../components/linkup/button/add-comment-button";
import ReactMarkdown from 'react-markdown';
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useRecentPosts } from "../../../contexts/recentPosts";
import { useComments } from "../../../contexts/comment";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const LinkupPost = () => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);

  const {recentPosts, updateRecentPosts} = useRecentPosts();
  const {addComment, fetchComments} = useComments();
  const {subname, pid, slug} = useParams();
  
  useEffect(() => {
    // console.log(subname, pid);
    const fetchPost = async () => {
      
      try {
        const headers = new Headers();
        headers.append("Content-Type", "application/json");
        // headers.append("Authorization", `Bearer ${jwtToken}`);
        
        const requestOptions = {
          method: "GET",
          headers: headers
        }
        const response = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/${pid}/${slug}`, requestOptions);
        const data = await response.json();
        setPost(data.post);

        
      } catch (err) {
        setError(err.message);
      }
    };
    fetchPost();

  },[subname, pid, slug]);

  useEffect(() => {
    if (post) {
      // console.log(post);
      fetchComments();
    }
  }, [post, fetchComments]);

  useEffect(() => {
    if (post) {
      handlePostClick(post);
    }
  }, [post]);

  const handlePostClick = (post) => {
    let updatedPosts = [post, ...recentPosts.filter(p => p.id !== post.id)];

    if (updatedPosts.length > 10) {
      updatedPosts = updatedPosts.slice(0, 10);
    }

    updateRecentPosts(updatedPosts);
    localStorage.setItem('recentPosts', JSON.stringify(updatedPosts));
  };

  const handleNewComment = (newComment) => {
    addComment(newComment);
  }
  
  if (error) {
    return <div>Error: {error}</div>
  }
  
  if (!post) {
    return <div>No post available</div>;
  }
  
  
  return (
    <>
      <Container px={[4]} >
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', py: 3 }}>
          <BackButton />
          <HeaderTag avatar={<SubthreadAvatar post={post} />} headerInfo={<HeaderInfoWithUsername post={post} />} sx={{mr: 'auto'}}/>
          <Dropdown username={post.username} url={post.url} />
        </Flex>
          <Box >
            <Heading as="h3" sx={{ mb: 2}}>
              {post.title}
            </Heading>
            <Paragraph sx={{ fontSize: '0.9rem'}}>
              <ReactQuill
                value={post.content}
                readOnly={true}
                theme="snow"
                modules={{toolbar: false}}
                className="quill-post-content"
              />
            {/* <ReactMarkdown
              className={"markdown-content"}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: ({ node, ...props }) => {
                  const { src, alt } = props;
            
                  // Handle base64 images
                  if (src && src.startsWith('data:image')) {
                    return <img {...props} alt={alt || "Base64 Image"} style={{ width: '50%', borderRadius: '10px' }} />;
                  }
                  
                  // Handle GIF images
                  if (src && src.endsWith('.gif')) {
                    return <img {...props} style={{ width: '25%', borderRadius: '5px' }} alt={alt || "GIF Image"} />;
                  }
            
                  // Handle JPEG and PNG images
                  if (src && (src.endsWith('.jpeg') || src.endsWith('.jpg') || src.endsWith('.png'))) {
                    return <img {...props} style={{ width: '50%', borderRadius: '10px' }} alt={alt || "Image"} />;
                  }
            
                  // Default image styling
                  return <img {...props} alt={alt || "Image"} />;
                }
                
              }}
            >
            {post.content}
            </ReactMarkdown> */}
          </Paragraph>
          <InteractBar post={post} />
        </Box>
        <Box py={3}>
          <AddComment post={post} setIsExpanded={() => {}} onNewComment={handleNewComment}/>
        </Box>
        <CommentsList post={post} />
      </Container>
    </>
  )
}

export default LinkupPost;