import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Container, Text, useColorMode } from "theme-ui";
import OverviewComments from "./OverviewComments";
import OverviewPosts from "./OverviewPosts";

const UserOverview = () => {
  const {username} = useParams();
  const [overview, setOverview] = useState([]);
  const [error, setError] = useState();
  const [colorMode] = useColorMode();
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    switch (item.type) {
      case "comment":
        navigate(`/linkup/p/${item.subthread_id}/comments/${item.post_id}/comment/${item.id}`, {state: {item}})
        break;
      case "post":
        navigate(`/linkup/p/${item.subthread_id}/comment/${item.id}/${item.post_slug}`);
        break;
      default:
        console.error("Unknown item type:", item.type);
        break;  
    }
  };
  
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/users/${username}`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      if (data.error) {
        setError(data.error.message);
        setOverview([]);
        return (
          <Container px={4}>
            <Text sx={{ color: 'red', fontWeight: 'bold', fontSize: '1.5rem', py: 4 }}>
              {error}
            </Text>
          </Container>
        )
      } else {
        setError(null);
        setOverview(data.overview || []);
      }
    })
    .catch((err) => {
      console.error("Error fetching user overview:", err);
      setError("An unexpected error occurred.");
      setOverview([]);
    });
  }, [username, setError, error]);

  return (
    <>
    {overview.map((item, index) => (
      <Box key={index} className="overview-item"
      sx={{
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        backgroundColor: colorMode === 'dark' ? 'background' : 'white',
        '&:hover': {
          backgroundColor: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#f0f0f0',
        },
        border: 0, borderBottom: '1px solid', borderColor: 'gray'
      }}
      onClick={() => handleItemClick(item)}

      >
        <>
          {item.type === "post" &&
            <OverviewPosts item={item} />
          }
          { item.type === "comment" &&
            <OverviewComments item={item} />
          }
        </>

      </Box>

    ))}
    </>
  )
}

export default UserOverview;