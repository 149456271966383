import { useEffect, useState } from "react";
import { PiEyeClosedBold } from "react-icons/pi";
import { FaRegEye } from "react-icons/fa";
import { Box, Heading, Text } from "theme-ui";


const Background = () => {
  const [blinking, setBlinking] = useState(false);

  useEffect(() => {
    const blink = () => {
      setBlinking(true);
      setTimeout(() => {
        setBlinking(false);
      }, 2000); // Close the eye for 2 seconds
    };

    const interval = setInterval(blink, 5000); // Blink every 17 seconds (15 seconds open + 2 seconds closed)
    blink(); // Initial blink to start the cycle

    return () => clearInterval(interval);
  }, []);

  return (
    <Box py={6}>
       <Heading as={'h1'}>Responsible. Responsive. Reliable.</Heading>
      <Text sx={{ fontSize: '1.2em', mt: 3 }}>
      These three qualities define my approach to work and are what clients and colleagues can consistently expect from me:
      </Text>
      <Box pl={3} mt={4}>
        <Text as={'h3'} sx={{ fontWeight: '600', lineHeight: '1.8', letterSpacing: '-0.06em' }}>
          1. Responsibility 
        </Text>
        <Text mt={2}>
        I take full ownership of my tasks, whether collaborating within a team or working directly with clients. I am committed to delivering on promises and seeing every project through to completion with accountability and dedication.
        </Text>
      </Box>
      <Box pl={3} mt={4}>
        <Text as={'h3'} sx={{ fontWeight: '600', lineHeight: '1.8', letterSpacing: '-0.06em' }}>
          2. Responsiveness 
        </Text>
        <Text mt={2}>
          Clear and timely communication is key to any successful project. I prioritize keeping clients and teammates informed, being adaptable, and addressing challenges as they arise, ensuring smooth collaboration and swift action.
        </Text>
      </Box>
      <Box pl={3} mt={4}>
        <Text as={'h3'} sx={{ fontWeight: '600', lineHeight: '1.8', letterSpacing: '-0.06em' }}>
          3. Reliability
        </Text>
        <Text mt={2}>
          Clients and teams can always count on me to meet deadlines and consistently deliver high-quality results.
        </Text>
      </Box>
      {/* Vision Heading and Statement */}
      <Box sx={{ textAlign: 'center', maxWidth: '800px', mx: 'auto', mt: 5 }}>
        {blinking ? <PiEyeClosedBold size={75}/> : <FaRegEye size={75} />}
        <Heading as={'h1'} mb={2}>Vision</Heading>
        <Text sx={{ fontSize: '1.1em', mt: 3, fontStyle: 'italic' }}>
          To bring value through functionality, innovation, and design.
        </Text>
      </Box>

      {/* Belief Statement */}
      <Box sx={{ maxWidth: '800px', mx: 'auto', mt: 4, textAlign: 'center' }}>
        <Text sx={{ lineHeight: '1.8', textAlign: 'center' }}>
          I believe a company's growth is driven by its willingness to learn and the humility to acknowledge that there is always room for improvement. 
          Even at the top, competitors are constantly striving to surpass you, which is why continuous progress is essential.
          Fortunately, your company is a family of innovators. At any moment, a team member could be the one to solve the next big challenge.
        </Text>
      </Box>

      {/* Trust Statement */}
      <Box sx={{ textAlign: 'center', maxWidth: '800px', mx: 'auto', mt: 4 }}>
        <Text sx={{ fontSize: '1.3em', letterSpacing: '-0.06em', mt: 4 }}>
          None of this would be possible without one essential factor:
        </Text>
        <Heading as={'h3'} sx={{ fontSize: '1.7em', my: 3, fontStyle: 'italic'}}>
          Trust.
        </Heading>
        <Text sx={{ mt: 4, fontStyle: 'italic' }}>
          Trust fosters creativity and drives success. Trust those who share your values. Trust in me.
        </Text>
      </Box>
    </Box>

  )
}

export default Background;