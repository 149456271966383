import { Container } from "theme-ui";
import Introduction from "../../../components/portfolio/introduction.component.jsx";
import Services from "../../../components/portfolio/services.component.jsx";
import { Fragment } from "react";
import Background from "../../../components/portfolio/Background.jsx";
import SocialMediaLinks from "../../../components/portfolio/SocialMediaLinks.jsx";



const Home = () => {
  return (
    <Fragment>
      <Container py={5} > 
        <Introduction />
        <Background />
        <Services />
        <SocialMediaLinks />
      </Container>
    </Fragment>
  );
}

export default Home;
