import { useContext, useEffect, useState } from "react";
import { Box, Button, Flex, Heading, Paragraph, Text } from "theme-ui";
import { useAlert } from "../../../contexts/alert";
import { AuthContext } from "../../../contexts/auth";
import { Link } from "react-router-dom";



const SubthreadInfo = ({subname}) => {
  const {jwtToken} = useContext(AuthContext);
  const [subthread, setSubthread] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subthreadJoin, setSubthreadJoin] = useState();
  const [subscribe, setSubscribe] = useState(false);
  const {showAlert} = useAlert();


  const handleSubscribe = (status) => {
    setSubscribe(status);
    const payload = {
      subthread_id: subname,
      subscribed: status,
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization':  `Bearer ${jwtToken}`
      },
      body: JSON.stringify(payload),
    }

    // console.log("Request Payload:", payload);
    // console.log("Request Options:", requestOptions);

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/${subname}/join`, requestOptions)
    .then((resp) => { 
      if (resp.status === 204) {
        return null; // No content to parse
      }
      return resp.json();
    })
    .then((data) => {
      if (data) {
        // console.log(data.subthread_joins)
        setSubthreadJoin(data.subthread_joins);
      } else {
        setSubthreadJoin(false);
      }
    })
    .catch((e) => {
      console.error(e);
      showAlert(e, 'alert');
    })

  }


  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", `Bearer ${jwtToken}`);
    
    const requestOptions = {
      method: "GET",
      headers: headers
    }
    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/posts/subthread/${subname}`, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then((data) => {
      // console.log(data);
      setSubthread(data.subthread);
      setSubscribe(data.subthread.subscribed);
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      showAlert(error, "alert");
      setLoading(false);
    })

    
  }, [subname, jwtToken, showAlert]);



  if (loading) {
    return <Text>Loading...</Text>
  }


  return (
    <Box sx={{pb: 3, borderBottom: '1px solid #d3d3d3'}}>
      <Flex
        sx={{
          alignItems: 'center',
          pb: '2'
        }}
      >
        <Link to={`/linkup/t/${subthread.id}`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          <Heading as={"h4"}>t/{subthread.id}</Heading>
        </Link>
        <Button 
          onClick={() => handleSubscribe(!subscribe)}
          sx={{
            ml:'auto', 
            cursor:'pointer', 
            borderRadius: '20px',
            fontSize: '0.9rem',
            color: subscribe ? 'black' : 'white',
            background: subscribe ? 'lightgrey' : 'primary',
          }}
        >{subscribe ? 'Unsubscribe' : 'Join'}</Button>
      </Flex>
      <Heading as={"h5"}>{subthread.name}</Heading>
      <Paragraph>{subthread.description}</Paragraph>
      
    </Box>
  )
}

export default SubthreadInfo;