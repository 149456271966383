import { Box, Flex } from "theme-ui";
import CollapseButton from "../button/collapse-button";
import ReplyButton from "../button/reply-button";
import Dropdown from "../dropdown/dropdown.component";
import CommentVote from "./comment-votes-pill.component";
import { useEffect, useRef, useState } from "react";
import ReplyForm from "../../form/reply-form";

const CommentInteract = ({isCollapsed, toggleCollapse, comment, post}) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const replyRef = useRef(null);


  const handleClickCommentOut = (e) => {
    if (replyRef.current && !replyRef.current.contains(e.target)){
      setShowReplyForm(false);
    }
  };

  useEffect(() => {
    if (showReplyForm) {
      document.addEventListener('mousedown', handleClickCommentOut);
    } else {
      document.removeEventListener('mousedown', handleClickCommentOut);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickCommentOut);
    }
    
  }, [showReplyForm]);

  const toggleShowReplyForm = () => {
    setShowReplyForm(prev => !prev);
  }

  const handleReplySubmit = (content) => {
    setShowReplyForm(false);
  };

  return (
    <>
      <Flex pt={1} sx={{ alignItems: 'center'}}>
        {comment.replies && comment.replies.length > 0 ? (
          <CollapseButton isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />
          
        ) : (
          
          <Box 
            sx={{ 
              p: 2,
              backgroundColor: 'transparent',
            }} 
          ><span style={{visibility: 'hidden', paddingRight: 2}}>hide</span></Box>
          
        )}
        <CommentVote comment={comment}/>
        <Box onClick={toggleShowReplyForm}>
          <ReplyButton />
        </Box>
        <Box>
          <Dropdown username={comment.username} replyRef={replyRef}/>
        </Box>
      </Flex>
      {showReplyForm && (
        <Flex>

        <Box 
          sx={{ 
            p: 2,
            backgroundColor: 'transparent',
          }} 
        ><span style={{visibility: 'hidden', paddingRight: 2}}>hide</span></Box>
        <Box ref={replyRef} py={3} sx={{width: '100%'}}>
          <ReplyForm parentId={comment.id} post={post} onReplySubmitted={handleReplySubmit} toggleShowReplyForm={toggleShowReplyForm} />
        </Box>
        </Flex>
      )}
    </>
  )
}

export default CommentInteract;