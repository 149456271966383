import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";


const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);
  const {username} = useParams();

  const fetchUserOverview = async () => {
    if (!username) return;
    try {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }
      const resp = await fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/users/${username}`, requestOptions);
      const data = await resp.json();
      if (data.error) {
        setError(data.error.message);
      } else {
        setError(null);
        setUser(data.user);
      }

    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchUserOverview();
  }, [username]);

  return (
    <UserContext.Provider value={{user, fetchUserOverview, error}}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext);