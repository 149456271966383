import { useEffect, useState } from "react"
import { Box, Grid } from "theme-ui";
import ListPost from "../../../components/linkup/card/list-post";
import { useParams } from "react-router-dom";


const PostsByUser = () => {
  const {username} = useParams();
  const [posts, setPosts] = useState([]);
  const [error] = useState(null);

  useEffect(() => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    }

    fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/users/${username}/submitted`, requestOptions)
    .then((response) => {

      return response.json();
    })
    .then((data) => {
      if (data.posts && Array.isArray(data.posts)){
        setPosts(data.posts);
      } else {
        throw new Error('Unexpected data format');
      }
    })
    .catch((err) => {
      console.error(err);
    });
  }, [username]);

  if (error) {
    return <div>Error: {error}</div>
  }
  if (!Array.isArray(posts)) {
    return <div>No posts available</div>
  }
  
  return (
    <>
      <Grid gap={1} columns={[1,1,1]}>
      
        {posts.map((post) => (
          <Box sx={{border: 0, borderBottom: '1px solid', borderColor: 'gray'}}>
            <ListPost key={post.id} post={post}/>
          </Box>
        ))}
      </Grid>
    </>
  )
}

export default PostsByUser;