import { createContext, useCallback, useEffect, useState } from "react";
import {formatErrors} from "../components/utils/auth/auth.utils";
import {jwtDecode} from "jwt-decode";

export const AuthContext = createContext();

const AuthProvider = ({children}) => {
  const [jwtToken, setJwtToken] = useState("");
  const [authUser, setAuthUser] = useState("");
  const [userRole, setUserRole] = useState("");
  const [alertMsg, setAlertMsg] = useState("");

  const [tickInterval, setTickInterval] = useState(null);

  // check if the token has expired
  // const isTokenExpired = (token) => {
  //   try {
  //     const {exp} = jwtDecode(token);
  //     return Date.now() >= exp * 1000;
  //   } catch {
  //     return true;
  //   }
  // }

  // Refresh the token 
  // const refreshToken = useCallback(() => {
  //   const requestOptions = {
  //     method: "GET", 
  //     credentials: "include",
  //   };

  //   fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/refresh`, requestOptions)
  //     .then((response) => {
  //       if (!response.ok) throw new Error(response.statusText);
  //       return response.json();
  //     })
  //     .then((data) => {
  //       setJwtToken(data.tokens.access_token);
  //       setUserRole(data.user.role);
  //       setAuthUser(data.user.username);
  //     })
  //     .catch((error) => {
  //       console.error("Error refreshing token:", error);
  //       setAlertMsg(error.message || "Failed to refresh token");
  //     });
  // }, []);
  
  // useEffect(() => {
  //   if (!jwtToken || isTokenExpired(jwtToken)) {
  //     refreshToken();
  //   }
  // }, [jwtToken, refreshToken]);

  const toggleRefresh = useCallback((status) => {
    if (status && !tickInterval) {
      let i = setInterval(() => {
        const requestOptions = {
          method: "GET",
          credentials: "include",
        };
        fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/refresh`, requestOptions)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // console.log(data);
          if (data.error) {
            let errorMessage = '';
            if (typeof data.error.message === 'object') {
              errorMessage = formatErrors(data.error.message);
            } else if (typeof data.error.message === 'string') {
              errorMessage = data.error.message;
            } else {
              errorMessage = 'Unknown error occurred';
            }
            setAlertMsg(errorMessage);
          } else {
            setJwtToken(data.tokens.access_token);
            setUserRole(data.user.role);
            setAuthUser(data.user.username);
            
          }

        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
      })
      }, 600000);
      setTickInterval(i);
    } else if (!status) {
      clearInterval(tickInterval);
      setTickInterval(null);
    }
  }, [tickInterval])

  useEffect(() => {
    if (!jwtToken) {
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };

      fetch(`${process.env.REACT_APP_LINKUP_APP_BACKEND}/v1/token/refresh`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Failed to refresh token. Status: " + response.status);
          }
          
        })
        .then((data) => {
          if (data.error) {
            let errorMessage = '';
            if (typeof data.error.message === 'object') {
              errorMessage = formatErrors(data.error.message);
            } else if (typeof data.error.message === 'string') {
              errorMessage = data.error.message;
            } else {
              errorMessage = 'Unknown error occurred';
            }
            setAlertMsg(errorMessage);
          } else {
            setJwtToken(data.tokens.access_token);
            setUserRole(data.user.role);
            setAuthUser(data.user.username);
            if (!tickInterval) toggleRefresh(true);
          }

        })
        .catch((error) => {
          console.error("Error refreshing token:", error);
          setAlertMsg(error.message || "Failed to refresh token");
          return
      });
    }
    return () => {
      if (tickInterval) {
        clearInterval(tickInterval);
      }
    };
  }, [jwtToken, toggleRefresh, tickInterval]);


  const value = {
    jwtToken,
    setJwtToken,
    authUser,
    setAuthUser,
    alertMsg,
    setAlertMsg,
    toggleRefresh,
    userRole,
    setUserRole
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;