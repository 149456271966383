import { useEffect, useState } from "react";
import {Box, Button, Paragraph, Flex} from 'theme-ui';
import { CloudinaryContext, Image as CloudinaryImage } from "cloudinary-react";

const ImageUploader = ({quillRef, setShowImageUploader}) => {
  // const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
    script.async = true;
    script.onload = () => setIsScriptLoaded(true);
    script.onerror = () => setError('Failed to load Cloudinary script.');
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  
  // const oldHandleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const validTypes = ['images/jpeg', 'image/png', 'images/gif'];
  //     if (validTypes.includes(file.type)){
  //       setSelectedFile(file);
  //       setPreviewUrl(URL.createObjectURL(file));
  //       setError(null);
  //     } else {
  //       setError('Only JPEG, PNG, and GIF files are allowed.');
  //       setSelectedFile(null);
  //       setPreviewUrl(null);
  //     }
  //   }
  // };

  // const oldHandleUpload = async () => {
  //   if (!selectedFile) {
  //     setError('No file selected.')
  //     return
  //   }
    
  //   if (quillRef?.current && selectedFile) {
  //     const editor = quillRef.current.getEditor();
  //     const range = editor.getSelection(true);
  //     if (range) {
  //       editor.insertEmbed(range.index, 'image', selectedFile);
  //       editor.setSelection(range.index + 1);
  //     }
  //   }
  //   const textarea = document.querySelector('.comment-textarea');
  //   if (textarea) {
  //     const start = textarea.selectionStart;
  //     const end = textarea.selectionEnd;
  //     const text = textarea.value;
  //     const before = text.substring(0, start);
  //     const after = text.substring(end);
  //     setPreviewUrl(`${before}![IMAGE](${selectedFile})${after}`);
  //     textarea.focus();
  //     setShowImageUploader(false);
  //   }

  // }

  // const handleFileChange = (error, result) => {
  //   if (error) {
  //     setError('Upload failed.');
  //     return;
  //   }
  //   if (result && result.event === 'success') {
  //     const url = result.info.secure_url;
  //     console.log('Uploaded image URL:', url);
  //     setPreviewUrl(url);
  //   }
  // }

  const handleUpload = () => {
    console.log(previewUrl);
    if (quillRef?.current) {
      const editor = quillRef.current.__quill;
      if (!editor) {
        console.error('Quill editor not found');
        return;
      }
      const range = editor.getSelection(true);
      // console.log('Quill editor range:', range);
      if (range) {
        // console.log('Inserting image at index:', range.index, 'with URL:', previewUrl);
        editor.insertEmbed(range.index, 'image', {src: previewUrl});
        editor.setSelection(range.index + 1, 0);
        editor.focus();
        // console.log('Image inserted at index:', range.index, 'with URL:', previewUrl);
      } else {
        console.error('Quill range not found');
      }
    } else {
      console.error('Quill ref not found');
    }
    setShowImageUploader(false);
  
  }

  const openWidget = (e) => {
    e.preventDefault();
    console.log('Cloud Name:', `${process.env.REACT_APP_CLOUD_NAME}`);
    console.log('Upload Preset:', `${process.env.REACT_APP_UPLOAD_PRESET}`);
    if (isScriptLoaded && window.cloudinary) {

      const widget = window.cloudinary.createUploadWidget(
        {
          cloudName: `${process.env.REACT_APP_CLOUD_NAME}`,
          uploadPreset: `${process.env.REACT_APP_UPLOAD_PRESET}`,
          sources: ['local', 'url'],
          showAdvancedOptions: false,
          // cropping: true,
          multiple: false,
          theme: 'minimal',
          maxImageWidth: 1000,
          maxImageHeight: 1000,
          maxFileSize: 4194304,
          transformation: [
            { width: 800, crop: 'scale' }, 
            { quality: 'auto', fetch_format: 'auto' }
          ],
        },
        (error, result) => {
          if (error) {
            setError('Upload failed.');
          } else if (result?.event === 'success') {
            const url = result.info.secure_url;
            setPreviewUrl(url);
          }
        }
      )
      widget.open();
      
    } else {
      setError('Cloudary script not loaded');
    }
  };

  

  return (
    <>
      {/* <Input type='file' accept='image/jpeg;image/png;image/gif;' onChange={handleFileChange} />
      {error && <Paragraph sx={{ color: 'red'}}>{error}</Paragraph>}
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between'}}>
        {previewUrl && (
          <Box>
            <Image src={previewUrl} alt="Preview" my={2} px={{ maxWidth: '100%'}}/>
          </Box>
        )}
        <Box>
          <Button mt={2} onClick={handleUpload}>Upload</Button>
        </Box>
      </Flex> */}
      <CloudinaryContext cloudName={`${process.env.REACT_APP_CLOUD_NAME}`}>
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
        <Box>
          {/* <Input type='file' onClick={openWidget} accept='image/*' /> */}
          <Button onClick={openWidget}>Choose File</Button>
        </Box>

        <Button onClick={handleUpload} disabled={!previewUrl}>Upload</Button>
      </Flex>
        {error && <Paragraph sx={{ color: 'red' }}>{error}</Paragraph>}
        {previewUrl && (
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
            <CloudinaryImage publicId={previewUrl} alt="Preview" width="300" crop="scale" />
          </Flex> 
        )}
      </CloudinaryContext>
    </>
  )
}

export default ImageUploader;