

export const formatErrors = (errors) => {
  let formattedError = "";
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      formattedError += `${key}: ${errors[key]}\n`;
    } else {
      formattedError += errors.message;
    }
  }
  return formattedError.trim();
}

export const validatePassword = (value) => {
  const minLength = 8;
  const hasNum = /\d/;
  const hasSpecialChar = /[!@#$%^&*]/;

  if (value.length < minLength) {
    return `Password must be at least ${minLength} characters long.`;
  }
  if (!hasNum.test(value)) {
    return "Password must include at least one number.";
  }
  if (!hasSpecialChar.test(value)) {
    return "Password must include at least one special character.";
  }
  return ""; // No error
}
