import { BsChat } from "react-icons/bs"
import { Box } from "theme-ui"



const ReplyButton = ({padding, size}) => {

  return (
    <Box
      mr={2}
      sx={{
        backgroundColor:'#f0f0f0',
        color: 'black',
        p: `${padding}`,
        borderRadius: '18px',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover':{
          backgroundColor: 'primary',
          color: 'white',
          
      }}}
    >
      <Box 
        ml={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '2rem',
          height: '1.5rem',
          borderRadius: '50%',
        }}
      >
        <BsChat
          size={size}
        />
      </Box>
      <Box
        
        mr={2}
        sx={{
          fontSize: '12px', 
          fontWeight: 'bold', 
          }}
      >
        <Box p={1}>
          Reply
        </Box>
      </Box>
    </Box>
  )
}

export default ReplyButton;