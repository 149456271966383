import { useState } from "react";
import { Box, Input } from "theme-ui";
import CommentForm from "../../form/comment-form";

const AddComment = ({post, onNewComment}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [setTempComment] = useState(null);
  
  
  const handleInputClick = () => {
    setIsExpanded(true);
  }

  const handleNewComment = (newComment) => {
    setTempComment(newComment);
    setIsExpanded(false);

    if (onNewComment) {
      onNewComment(newComment);
    }
  }

  
  return (
    <Box sx={{width: '100%', fontSize: '0.9rem'}}>
      {isExpanded ? (
        <CommentForm post={post} setIsExpanded={setIsExpanded} onNewComment={handleNewComment}/> 
      ) : (
        <>
          <Input 
            placeholder="Add a comment"
            onClick={handleInputClick}
            readOnly
          />
        </>
      )}
    </Box>
  )
}

export default AddComment;